<template>
<form @submit.stop.prevent="submit" class="company-form">
  <loading-overlay :active="isLoadingGeneral" :is-full-page="true" loader="bars" />
   <bay-plan-modal :modal.sync="modalBayPlan" :bay="selectedBayPlan" :buque="buque"/>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardBody>
          <CRow>
            <CCol sm="1" class="d-flex flex-column align-items-center bay-row align-self-center">
              <div class="position-relative">
                <div
                  v-for="item in formatedBays"
                  :key="item.BayCode"
                  class="bay-item text-white"
                  :class="{ 
                    'bg-success' : item.Free && !item.selected,
                    'bg-danger' : !item.Free && !item.selected,
                    'bg-warning' : item.selected,
                  }"
                  @click.stop="selectBay(item)"
                >
                  {{ item.BayCode }}
                </div>
                <CElementCover
                  :opacity="1"
                  v-show="isLoading"
                >
                  <CSpinner size="sm" />
                </CElementCover>
              </div>
            </CCol>
            <CCol sm="11">
              <CRow align-horizontal="center">
                <CCol sm="10"> 
                  <CInput
                    v-model.trim="buqueIMO"
                    v-uppercase
                    :horizontal="{ label: 'col-sm-2', input: 'col-sm-10'}"
                    disabled
                    size="sm"
                    :label="$t('label.vessel')"
                    addLabelClasses="text-right"
                    maxlength="200"
                  >
                  </CInput>
                </CCol>
              </CRow>
              <CRow class="mt-2">
                <CCol sm="12" v-if="!isBaySelected">
                  <dataTableExtended
     class="align-center-row-datatable"
                    hover
                    sorter
                    column-filter
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :items="formatedBaysList"
                    :fields="fields"
                    :loading="isLoading"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="5"
                    :active-page="activePage"
                    pagination
                  >
                  <template #loading>
                    <loading/>
                  </template>
                    <template #Nro="{ item }">
                      <td class="center-cell-vertical">
                        {{ item.Nro }}
                      </td>
                    </template>
                    <template #BayCode="{ item }">
                      <td class="center-cell-vertical">
                        {{ item.BayCode }}
                      </td>
                    </template>
                    <template #ToDeck="{ item }">
                      <td class="center-cell-vertical">
                        {{ item.ToDeck }}
                      </td>
                    </template>
                    <template #UnderDeck="{ item }">
                      <td class="center-cell-vertical">
                        {{ item.UnderDeck }}
                      </td>
                    </template>
                    <template #UltimoUsuario="{ item }">
                      <td class="center-cell-vertical">
                        {{ item.UltimoUsuario }}
                      </td>
                    </template>
                    <template #FormatedDate="{ item }">
                      <td class="center-cell">
                        {{ item.FormatedDate }}
                      </td>
                    </template>
                    <template #Details="{item, index}">
                      <td class=" center-cell">
                        <CButtonGroup>
                          <CButton
                            class="mr-1"
                            shape="square"
                            color="plan"
                            size="sm"
                            :disabled="isDeleting"
                            v-c-tooltip="{
                              content: 'BAY PLAN',
                              placement: 'top-end'
                            }"
                            @click="toggleBayPlan(item, index)"
                          >
                            <CIcon name="bay" />
                          </CButton>
                          <CButton
                            color="wipe"
                            size="sm"
                            v-c-tooltip="{
                              content: `${$t('label.delete')}`,
                              placement: 'top-end',
                            }"
                            :disabled="isDeleting"
                            @click.stop="deleteConfirmation(item)"
                          >
                            <CIcon name="cil-trash" v-if="!isDeleting"/>
                            <span v-if="isDeleting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </CButton>
                        </CButtonGroup>
                      </td>
                    </template>
                  </dataTableExtended>
                </CCol>
                <CCol sm="12" v-else>
                  <transition name="fade">
                    <div class="form-step" v-if="isBaySelected"> 
                      <transition name="slide-fade">
                        <div v-if="currentStep == 0">
                          <bay-tier-rows :defaultCode="bay.BayCode" @generated="handleGenerated"/>
                        </div>
                        <div v-else-if="currentStep == 1">
                          <bay-positions/>
                        </div>
                        <div v-else-if="currentStep == 2">
                          <bay-pair/>
                        </div>
                        <div v-else-if="currentStep == 3">
                          <bay-plan :buque="buque"/>
                        </div>
                      </transition>
                    </div>
                  </transition>
                </CCol>
              </CRow>
              <form-controls
                v-if="isBaySelected"
                :steps="maxSteps"
                :current="currentStep"
                :preText="preBtnTexts"
                :nextText="nextBtnTexts"
                @step-action="stepAction"
              />
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <div class="leyenda-container">
            <span class="font-weight-bold mr-1">{{$t('label.legend')+':'}}</span>
            <div class="d-flex align-items-center mr-1">
              <div class="box bg-warning"></div>
              <span class="ml-1">{{$t('label.preselected')}}</span>
            </div>
            <div class="d-flex align-items-center">
              <div class="box bg-danger"></div>
              <span class="ml-1">{{$t('label.previouslyAssigned')}}</span>
            </div>
            <div class="d-flex align-items-center">
              <div class="box bg-success"></div>
              <span class="ml-1">{{$t('label.availableAssign')}}</span>
            </div>
          </div>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</form>
</template>

<script>
import { mapState } from 'vuex';
import UpperCase  from '@/_validations/uppercase-directive';
import BayTierRows from '@/pages/buque/bays/tier-rows';
import BayPositions from '@/pages/buque/bays/positions';
import BayPair from '@/pages/buque/bays/bay-pair';
import BayPlan from '@/pages/buque/bays/bay-plan';
import FormControls from '@/pages/buque/bays/form-controls';
import { DateFormater, tableTextTranslatedHelpers, alertPropertiesHelpers } from '@/_helpers/funciones';
import BayPlanModal from '@/pages/buque/bays/bay-plan-modal';

const tableTextHelpers = {
  tableFilterText: {
    label: 'Filtrar:',
    placeholder: ' '
  },
  itemsPerPageText: {
    label: 'Bays por Pagina:'
  },
  noItemsViewText: { 
    noResults: 'No se han encontrado resultados', 
    noItems: 'No hay bodegas asignadas'
  },
};

function fields(){
  return  [
    { 
      key: 'Nro', 
      label: '#',
      _style: 'width:1%; text-align: center;',
      filter: false
    },
    { key: 'BayCode', label: this.$t('label.bay')},
    { key: 'ToDeck', label: this.$t('label.onDeck2')},
    { key: 'UnderDeck', label: this.$t('label.Underdeck')},
    { key: 'UltimoUsuario', label: this.$t('label.user'), _style: 'width:10%; '},
    { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width:10%; text-align: center;'},
    { 
      key: 'Details', 
      label: '', 
      _style: 'width:1%', 
      sorter: false, 
      filter: false,
    }
  ];
}

//Created:
function created() {
  this.generateBays();
 }

//Data:
function data() {
  return {
    isSubmit: false,
    isLoading: false,
    isLoadingGeneral: false,
    baysList: [],
    bays: [],
    bay: {
      VesselBayId: '',
      BayCode: 'N/A'
    },
    maxSteps: 4,
    activePage: 1,
    modalBayPlan: false,
    selectedBayPlan: null,
    isDeleting: false,
  }
}

//Methods:
function stepAction(num) {
  switch (num) {
    case 0:
      this.$store.commit('baygrid/nextStep');
      break;
    case 1:
      if(Array.isArray(this.positions) && this.positions.length != 0) {
        this.updatePositions();
      } else {
        this.$store.commit('baygrid/nextStep');
      }
      break;
    case 2:
      //Aqui va metodo para guardar bays
      this.$store.commit('baygrid/nextStep');
      break;
    case 3:
      this.$store.commit('baygrid/nextStep');
      break;
    default:
      break;
  }
}
function updatePositions() {
  this.$store.commit('baygrid/setLoadingForm', true);

  this.$store.dispatch('baygrid/updatePositions', this.positions)
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡Exito!',
      text: response[0].Response,
      type: "success"
    });
    this.$store.commit('baygrid/nextStep');
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.$store.commit('baygrid/setLoadingForm', false);
  });
}
function getBays(IdBuque) {  
  this.isLoading = true;

  this.$http.ejecutar('GET', 'VesselBay-list', { VesselId: IdBuque, Filter: 'ACTIVO' })
  .then(response => {
    this.baysList = response.data.data;
    if(this.baysList.length != 0)
      this.$store.commit('baygrid/setBayQuantity', Number.parseInt(this.baysList[this.baysList.length-1].BayCode)+1);
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}
function generateBays() {
  for (let index = 1; index < 100; index++) {
    if(Math.abs(index % 2) == 1)
      this.bays.push({
        BayId: index,
        BayCode: index < 10?`00${index}`:`0${index}`,
        Free: true
      });
  }
}
function toggleBayPlan(item, index) {
  this.selectedBayPlan = Object.assign({}, item);
  this.modalBayPlan = true;
}
function selectBay(item) {
  if(this.bay.BayCode !='N/A' && this.bay.BayCode == item.BayCode){
     this.bay = Object.assign({}, {
        VesselBayId: '',
        BayCode: 'N/A'
      });
      this.$store.commit('baygrid/defaultValues');
      this.$store.commit('baygrid/resetCurrentStep');
      return;
  }

  this.resetBay();
  if(item.Free && item.BayId != 1) {
    let code = (Number.parseInt(item.BayCode) - 2) < 10
      ? `00${Number.parseInt(item.BayCode) - 2}`
      : `0${Number.parseInt(item.BayCode) - 2}`;
    let found = this.baysList.findIndex((bay) => bay.BayCode === code);
    if(found != -1) {
      this.$store.commit('baygrid/defaultValues');
      this.$store.commit('baygrid/resetCurrentStep');
      this.bay = Object.assign({}, item);
    }
  }else {
    this.$store.commit('baygrid/defaultValues');
    this.$store.commit('baygrid/resetCurrentStep');
    this.bay = Object.assign({}, item);
  }

  if(this.bay.VesselBayId) {
    this.isLoadingGeneral = true;
    this.$store.dispatch('baygrid/getBayById', this.bay.VesselBayId)
    .then((response) => {
      this.bay = {...this.bay,...response};
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isLoadingGeneral = false;
    });
  }
}
function handleGenerated(type) {
  if(!type){
    this.bay = Object.assign({}, {
      VesselBayId: '',
      BayCode: 'N/A'
    });
  }
  
  this.getBays(this.buque.VesselId);
}
function resetBay() {
  this.bay = Object.assign({}, {
    VesselBayId: '',
    BayCode: 'N/A'
  });
}

function deleteConfirmation(item) {
  this.$swal.fire(alertPropertiesHelpers(this,{
    text: `${this.$t('label.deleteBay')}`,
  })).then((result) => {
    if (result.isConfirmed) {
      this.desactivarBuque(item);
    }
  });
}
function desactivarBuque(item) {
  this.isDeleting = true;
  this.isLoadingGeneral = true;

  let VesselBayJson = {
    VesselBayId: item.VesselBayId,
    VesselBayIdPair: item.VesselBayIdPair,
    VesselId: item.VesselId,
    VesselHoldId: item.VesselHoldId,
    BayCode: item.BayCode,
    FgUnderDeck: item.FgUnderDeck,
    TierUnderDeckFrom: item.TierUnderDeckFrom,
    TierUnderDeckTo: item.TierUnderDeckTo,
    RowStarboardUnderDeck: item.RowStarboardUnderDeck ? item.RowStarboardUnderDeck : '',
    RowPortUnderDeck: item.RowPortUnderDeck ? item.RowPortUnderDeck : '',
    FgCrossLineUnderDeck: item.FgCrossLineUnderDeck ? item.FgCrossLineUnderDeck : '',
    FgToDeck: item.FgToDeck,
    TierToDeckFrom: item.TierToDeckFrom,
    TierToDeckTo: item.TierToDeckTo,
    RowStarboardToDeck: item.RowStarboardToDeck ? item.RowStarboardToDeck : '',
    RowPortToDeck: item.RowPortToDeck ? item.RowPortToDeck : '',
    FgCrossLineToDeck: item.FgCrossLineToDeck ? item.FgCrossLineToDeck : '',
    Status: 0
  };

  this.$http.ejecutar('PUT', 'VesselBay-update', VesselBayJson, { root: 'VesselBayJson' })
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡Exito!',
      text: response.data.data[0].Response,
      type: "success"
    });
    this.getBays(this.buque.VesselId);
    this.$store.commit('baygrid/setVesselId', this.buque.VesselId);
    if(this.bays.length == 0)
      this.generateBays();
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.isLoading = false;
  }).then(() => {
    this.isLoadingGeneral = false;
    this.isDeleting = false;
  });
}

//Computerd: 
function buqueIMO() {
  return this.buque && this.buque.VesselName && this.buque.Imo
    ?`${this.buque.VesselName} - ${this.buque.Imo}`
    :'';
}
function formatedBays() {
  return this.bays.map((item) => {
    let found = this.baysList.findIndex((bay) => bay.BayCode === item.BayCode);

    if(found != -1){
      return Object.assign({}, item, { 
        Free: found == -1 ? true : false,
        ...this.baysList[found],
        selected: this.bay.BayCode ? item.BayCode == this.bay.BayCode : false
      });
    }else{
      return Object.assign({}, item, { 
        Free: found == -1 ? true : false,
        selected: this.bay.BayCode ? item.BayCode == this.bay.BayCode : false
      });
    }
  });
}
function isBaySelected() {
  return this.bay.BayCode != 'N/A';
}
function formatedBaysList() {
  return this.baysList.map((item) => Object.assign({}, item, {
    ToDeck: item.FgToDeck? 'SI' :'NO',
    UnderDeck: item.FgUnderDeck? 'SI' :'NO',
    UltimoUsuario: item.TransaLogin? item.TransaLogin:'N/A',
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
  }));
}

function preBtnTexts() {
  return [
    this.$t('label.tierRows'),
    this.$t('label.positions'),
    this.$t('label.associateBayPair')
  ];
}
function nextBtnTexts() {
  return [
    this.$t('label.positions'),
    this.$t('label.associateBayPair'),
    'BAYPLAN'
  ];
}

function tableText(){
  return tableTextTranslatedHelpers(this);
}

export default {
  name: 'bay-index',
  mixins: [],
  props: {
    buque: {
      type: Object,
      required: true,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BayTierRows,
    BayPositions,
    BayPair,
    BayPlan,
    FormControls,
    BayPlanModal,
    
  },
  data,
  directives: UpperCase,
  created,
  methods: {
    getBays,
    generateBays,
    selectBay,
    stepAction,
    handleGenerated,
    updatePositions,
    resetBay, 
    toggleBayPlan,
    deleteConfirmation,
    desactivarBuque,
  },
  computed: {
    buqueIMO,
    formatedBays,
    isBaySelected,
    formatedBaysList,
    preBtnTexts,
    nextBtnTexts,
    tableText,
    fields,
    ...mapState({
      currentStep: state => state.baygrid.current,
      positions: state => state.baygrid.selectedPositions
    })
  },
  watch: {
    active: function(newVal) {
      if(newVal){
        if( Object.keys(this.buque).length != 0 && this.buque.VesselId) {
          this.getBays(this.buque.VesselId);
          this.$store.commit('baygrid/setVesselId', this.buque.VesselId);
          if(this.bays.length == 0)
            this.generateBays();
        }
      } else {
        this.baysList = [];
        this.bays = [];
        this.selectedBayPlan =  null;
        this.bay = Object.assign({}, {
          VesselBayId: '',
          BayCode: 'N/A'
        });
      }
    }
  }
}
</script>

<style scoped>
.bay-row {
  max-height: 550px;
  overflow: auto;
  scrollbar-width: thin;
}
.bay-item {
  width: 2em;
  height: 2em;
  border: 1px solid #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leyenda-container {
  flex: 1;

  display: flex;
  justify-content: space-evenly;
}
.box {
  width: 3em;
  height: 3em;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.center-cell {
  text-align: center;
  vertical-align: middle;
}
.center-cell-vertical {
  vertical-align: middle;
}
</style>