import {
  required,
  maxLength,
  minLength,
  helpers,
} from 'vuelidate/lib/validators';

import { onlyText, metrics, onlyNumbers } from '../funciones';

const isValidFile = (value) => {
  if(!value)
    return false;
  
  if(value.type)
    return value.type.startsWith('image');
  else
    return (value != '');
};
const fileNotReq = (value) => !helpers.req(value) || isValidFile(value);

const metricsNotReq = (value) => !helpers.req(value) || metrics(value);

const validIMO = (value) => /^(IMO)(\d{7})$/.test(value);
const alphaNum = (value) => /^[a-zA-Z0-9\u00f1\u00d1]+$/g.test(value);
const alphaNumNotReq = (value) => !helpers.req(value) || alphaNum(value);

export default (FgTeus) => {
  let TeusValidate = FgTeus ? { metricsNotReq, maxLength: maxLength(20) } : {}
  return {
    buque: {
      VesselName: { required, onlyText, maxLength: maxLength(100) },
      Imo: { required, validIMO, maxLength: maxLength(10) },
      Mmsi: { required, onlyNumbers, minLength: minLength(9), maxLength: maxLength(9) },
      CallSign: { required, alphaNum, maxLength: maxLength(10) },
      Loa: { required, metrics, maxLength: maxLength(20) },
      Lwl: { metricsNotReq, maxLength: maxLength(20) },
      Teus: TeusValidate,
      Gross: { required, metrics, maxLength: maxLength(20) },
      DraftBow: { required, metrics, maxLength: maxLength(20) },
      DraftStern: { required, metrics, maxLength: maxLength(20) },
      SummerDwt: { metricsNotReq, maxLength: maxLength(20) },
      VesselCrane: { required },
      FuelOilCapacity: { metricsNotReq, maxLength: maxLength(20) },
      VesselRoute: { isValidFile }
    },
    tpBuque: { required },
    pais: { required }
  }
}