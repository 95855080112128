<template>
<CModalExtended
  :title="$t('label.nuevo')+$t('label.craneType')"
  color="dark"
  size="lg"
  :close-on-backdrop="false"
  :show.sync="modalActive"
>
  <form @submit.stop.prevent="submit" class="company-form">
    <CRow>
      <CCol sm="12">
        <CCard class="card-simple">
          <CCardBody>
            <CRow  class="mb-3">
              <CCol sm="4">
                <div class="preview">    
                  <picture-input
                    v-if="modalActive"
                    ref="imageInput"
                    width="200"
                    height="200"
                    accept="image/jpeg,image/png"
                    size="10"
                    :custom-strings="imgInputTexts"
                    @change="handleFileUpload"
                    :imgSrc="imageRoute"
                  >
                  </picture-input>
                </div>
                <div v-if="$v.tipoGrua.TpCraneRoute.$error">
                  <div class='text-invalid-feedback' v-if="!$v.tipoGrua.TpCraneRoute.isValidFile">{{$t('label.badImage')}}</div>
                </div>
              </CCol>
              <CCol sm="8">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      v-model.trim="$v.tipoGrua.TpCraneName.$model"
                      v-uppercase
                      :invalid-feedback="errorMessage($v.tipoGrua.TpCraneName)"
                      :is-valid="hasError($v.tipoGrua.TpCraneName)"
                      :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                      size="sm"
                      :label="$t('label.name')"
                      addLabelClasses="required text-right"
                      :placeholder="$t('label.craneTypeName')"
                      maxlength="100"
                    />
                  </CCol>
                  <CCol sm="12">
                    <CInput
                      v-model.trim="$v.tipoGrua.MaxHeigth.$model"
                      placeholder="0"
                      :invalid-feedback="errorMessage($v.tipoGrua.MaxHeigth)"
                      :is-valid="hasError($v.tipoGrua.MaxHeigth)"
                      :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                      size="sm"
                      :label="$t('label.maxHeigth')+' (MTR)'"
                      addLabelClasses="required text-right"
                      maxlength="20"
                    />
                  </CCol>
                  <CCol sm="12">
                    <CInput
                      v-model.trim="$v.tipoGrua.MaxWeigth.$model"
                      placeholder="0"
                      :invalid-feedback="errorMessage($v.tipoGrua.MaxWeigth)"
                      :is-valid="hasError($v.tipoGrua.MaxWeigth)"
                      :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                      size="sm"
                      :label="$t('label.maxWeight')+' (TON)'"
                      addLabelClasses="required text-right"
                      maxlength="20"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>         
  </form>
  <template #footer><!--:disabled="isSubmitValid"-->
    <CButton
      color="add"
      class="d-flex align-items-center"
      @click.stop="submitFile"
      :disabled="isSubmit"
    >
        <CIcon name="checkAlt"/><span class="ml-1">{{ $t('button.accept')}} </span>
    <!--
      <div v-if="!isSubmit">
        <CIcon name="checkAlt"/><span class="ml-1">{{ $t('button.accept')}} </span>
      </div>
      
      <div v-if="isSubmit">
        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="ml-1">{{ $t('button.accept') }} </span>
      </div>-->
    </CButton>
    <CButton
      color="wipe"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/><span class="ml-1">{{ $t('button.cancel')}}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import TipoGruaValidations from '@/_validations/tipo-grua/TipoGruaFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import TipoGruaMixin from '@/_mixins/tipo-grua';
import ModalMixin from '@/_mixins/modal';

import PictureInput from '@/components/picture-input';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    tipoGrua: {
      TpCraneName: '',
      MaxHeigth: '',
      MaxWeigth: '',
      TpCraneRoute: ''
    },
    image: '',
    imageRoute: '/img/uploadPhotoEs.svg',
  }
}

//Methods
function submitFile() {
  try {
  this.isSubmit = true;
  this.$v.tipoGrua.$touch();
    if (this.$v.tipoGrua.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
      this.$http.file('TpCrane-insert', this.tipoGrua.TpCraneRoute)
      .then((response) => {
        this.tipoGrua.TpCraneRoute = response.data.data.files[0].path;
        this.submit();
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.isSubmit = false;
      });
   } catch (e) {
    this.notifyError({ text: e });
  }
}

function submit() {
  try {
    this.isSubmit = true;
    this.$v.tipoGrua.$touch();
    if (this.$v.tipoGrua.$error) {
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let TpCraneJson = {
      TpCraneName: this.tipoGrua.TpCraneName,
      MaxHeigth: this.tipoGrua.MaxHeigth,
      MaxWeigth: this.tipoGrua.MaxWeigth,
      TpCraneRoute: this.tipoGrua.TpCraneRoute
    };

    this.$http.post('TpCrane-insert', TpCraneJson, { root: 'TpCraneJson' })
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function resetForm() {
  this.tipoGrua.TpCraneName = '';
  this.tipoGrua.MaxHeigth = '';
  this.tipoGrua.MaxWeigth = '';
  this.tipoGrua.TpCraneRoute = '';
  this.image = '';
  this.$v.$reset();
}
function handleFileUpload(image) {
  this.tipoGrua.TpCraneRoute = this.$refs.imageInput.file;
  this.image = image
}

export default {
  name: 'add-tipo-buque-modal',
  mixins: [
    TipoGruaMixin,
    ModalMixin,
  ],
  props: {
    modal: Boolean
  },
  components: {
    PictureInput
  },
  data,
  validations: TipoGruaValidations,
  directives: UpperCase,
  methods: {
    submit,
    resetForm,
    handleFileUpload,
    submitFile
  },
  computed: {  },
  watch: {
    modal: async function () {
      if (this.modal) {
        let _lang = this.$i18n.locale;
        this.imageRoute = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
      }
    },
   }
}
</script>

<style scoped>
.preview {
  height: 15em;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }

.text-invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e55353;
    padding-left: 0.8rem !important;
}
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
</style>