import {
  required,
  maxLength,
  helpers
} from 'vuelidate/lib/validators';
import { NumberFormater } from '@/_helpers/funciones';

import { metrics } from '../funciones';

const alphaNum = (value) => /^[\w ]+$/g.test(value);
const metricsNotReq = (value) => !helpers.req(value) || metrics(value);

export default (isCargoVessel, isGeneralCargo) => {
  let BaleValidate = isCargoVessel ? {
    required(value){
      return NumberFormater.setNum(value) > 0;
    },
  } : {};
  let VolumenValidate = isGeneralCargo ? {
    required(value){
      return NumberFormater.setNum(value) > 0;
    },
  } : {};
  return {
    bodega: {
      VesselId: {},
      VesselHoldName: { required, alphaNum, maxLength: maxLength(150) },
      Bale: BaleValidate,
      BaleOnGrain: BaleValidate,
      BaleOnDeck: {},
      Volumen: VolumenValidate,
    }
  }
}