<template>
  <div class="mb-4 mt-4">
    <CRow class="justify-content-center">

      <CCol sm="7">
        <CRow>
            <CCol sm="12">
              <CInput
                v-model.trim="buqueIMO"
                v-uppercase
                :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                disabled
                size="sm"
                :label="$t('label.vessel')"
                addLabelClasses="text-right"
                maxlength="200"
              >
              </CInput>              
            </CCol>
            <CCol sm="12">
              <div class="position-relative">
                <div class="form-group form-row" rol="group">
                  <label class="required text-right col-form-label col-sm-3 col-form-label-sm" for="tpGrua">{{$t('label.craneType')}}</label>
                  <div class="col-sm-9 input-group">
                    <v-select 
                      :options="tpGruasOptions"
                      label="TpCraneName"
                      :placeholder="$t('label.select')"
                      class="select-adjust"
                      v-model="TpGrua"
                      :disabled="isTpGruasEmpty"
                    >
                      <!--
                      <template #list-header>
                        <option @click.stop="toggleTpGruaAdd"> <strong>{{$t('label.nuevo')+' '+$t('label.craneType')}}</strong></option>
                      </template>
                      -->
                    </v-select>
                    <div class="input-group-append">
                      <div class="d-flex align-items-start">
                        <CButton
                          shape="square"
                          color="edit"
                          size="sm"
                          class="d-flex align-items-center"
                          @click.stop="toggleTpGruaAdd"
                          v-c-tooltip="{
                            content: $t('label.add')+' '+$t('label.craneType'),
                            placement: 'top-end'
                          }"
                        >
                          <CIcon name="cil-playlist-add"/>
                        </CButton>
                      </div>
                    </div>
                    <div :class="isTpGruaValid">
                      {{$t('label.required')}}
                    </div>
                  </div>
                </div>
                <CElementCover
                  :opacity="1"
                  v-show="isLoadingTpGrua"
                >
                  <label class="d-inline">{{$t('label.load')}}... </label>
                  <CSpinner size="sm" />
                </CElementCover>
              </div>
            </CCol>
            <CCol sm="12">
              <CInput
                v-model.trim="$v.grua.CraneName.$model"
                v-uppercase
                :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                :invalid-feedback="errorMessage($v.grua.CraneName)"
                :is-valid="hasError($v.grua.CraneName)"
                size="sm"
                :label="$t('label.craneName')"
                addLabelClasses="text-right required"
                maxlength="150"
              >
              </CInput>        
            </CCol>                        
        </CRow> 
      </CCol>
      <CCol sm="4">
        <CRow>
          <CCol sm="12">
            <div class="form-group form-row">
              <label class="text-right col-sm-7 col-lg-7 required col-form-label-sm">{{ $t('label.maxWeight')+' (TON)' }}</label>
              <div class="input-group col-sm-5 col-lg-5 input-group-sm">
                <money
                  v-uppercase
                  v-model.trim="grua.MaxWeigth"
                  v-bind="measure" 
                  :class="ValidateFormControl(grua.MaxWeigth)"
                >
                </money>
                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((grua.MaxWeigth == 0 || grua.MaxWeigth == '0,00') && valid==true)">
                  {{$t('label.required')}}
                </CCol>
              </div>
            </div>
          </CCol>
          <CCol sm="12">
            <div class="form-group form-row">
              <label class="text-right col-sm-7 col-lg-7 required col-form-label-sm">{{ $t('label.maxRange')+' (MTR)' }}</label>
              <div class="input-group col-sm-5 col-lg-5 input-group-sm">
                <money
                  v-uppercase
                  v-model.trim="grua.MaxHeigth"
                  v-bind="measure" 
                  :class="ValidateFormControl(grua.MaxHeigth)"
                >
                </money>
                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((grua.MaxHeigth == 0 || grua.MaxHeigth == '0,00') && valid==true)">
                  {{$t('label.required')}}
                </CCol>
              </div>
            </div>
          </CCol>
          <CCol sm="12">
            <CSelect
              :value.sync="grua.FgOperational"
              size="sm"
              :label="$t('label.operativa')+'?'"
              :horizontal="{ label: 'col-sm-7', input: 'col-sm-5'}"
              :options="operativaOptions"
              addLabelClasses="text-right required"
              class="mt-1"
              :is-valid="hasError($v.grua.FgOperational)"
            />
          </CCol>
          <CCol sm="12">
            <CCollapse :show="edit">
              <CSelect
                :value.sync="grua.Status"
                :is-valid="statusSelectColor"
                size="sm"
                :label="$t('label.status')"
                :horizontal="{ label: 'col-sm-7', input: 'col-sm-5'}"
                :options="statusOptions"
                addLabelClasses="text-right"
                class="mt-1"
              />
            </CCollapse>
          </CCol>           
        </CRow> 
      </CCol>
      <CCol sm="1">
        <CButton
          size="sm"
          color="add"
          :disabled="isSubmitValid"
          class="mr-1"
          @click.stop="statusConfirmation"
          v-c-tooltip="{
              content: $t('label.grabar'),
              placement: 'top-end'
            }"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt"/>
          </div>
          <div v-if="isSubmit">
            <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </div>
        </CButton>
        <CButton
          size="sm"
          color="wipe"
          :disabled="isSubmit"
          @click.stop="resetInputs"
          v-c-tooltip="{
                        content: $t('label.clearFields'),
                        placement: 'top-end'
                      }"
          >
            <CIcon name="cil-brush-alt" />
        </CButton>
      </CCol>
    </CRow>

<!--
    <CRow>
      <CCol sm="12" class="d-flex justify-content-end align-items-center">
        <CButton
          size="sm"
          color="add"
          shape="square"
          :disabled="isSubmitValid"
          class="d-flex align-items-center mr-2"
          @click.stop="statusConfirmation"
        >
          <div v-if="!isSubmit">
            <CIcon name="checkAlt"/><span class="ml-1">{{ submitText }}</span>
          </div>
          <div v-if="isSubmit">
            <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="ml-1">{{ submitText }}</span>
          </div>
        </CButton>
        <CButton
          size="sm"
          color="wipe"
          shape="square"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="resetInputs"
          v-c-tooltip="{
                        content: $t('label.clearFields'),
                        placement: 'top-end'
                      }"
          >
            <CIcon name="cil-brush-alt" />
        </CButton>
      </CCol>
    </CRow> -->
    <CRow class="mt-4">
      <CCol sm="12">
        <dataTableExtended
     class="align-center-row-datatable"
          hover
          sorter
          column-filter
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :items="formatedGrua"
          :fields="fields"
          :loading="isLoading"
          :noItemsView="tableText.noItemsViewText"
          :items-per-page="5"
          :active-page="activePage"
          pagination
           
        >
          <template #loading>
            <loading/>
          </template>
          <template #RowNumber="{ item }">
            <td class="center-cell text-center">
              {{ item.RowNumber }}
            </td>
          </template>

          <template #Status="{item}">
            <td class="text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>

          <template #Details="{item}">
            <td class="py-2 center-cell text-center">
                <CButton
                  color="edit"
                  size="sm"
                  :key="item.Nro"
                  :disabled="edit"
                  v-c-tooltip="{
                    content: $t('label.edit')+$t('label.crane'),
                    placement: 'top-end'
                  }"
                  @click.stop="handleEdit(item)"
                >
                  <CIcon name="pencil" />
                </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
    
    <add-tp-grua-modal :modal.sync="modalAddTpGrua" @submited="handleTpGruaSubmit"/>
  </div>
</template>

<script>
import UpperCase  from '@/_validations/uppercase-directive';
import BuqueGruaValidations from '@/_validations/buque/BuqueGruaValidations';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import { VMoney, Money } from "v-money";

import AddTpGruaModal  from '@/pages/tipo-grua/add-tipo-grua-modal';

function fields(){
  return [
    { 
      key: 'RowNumber', 
      label: '#',
      _style: 'width:1%; text-align:center;',
      filter: false,

    },
    { key: 'TpGrua', label: this.$t('label.craneType'), _style: 'width:20%' },
    { key: 'CraneName', label: this.$t('label.crane')+'S', _style: 'width:20%' },
    { key: 'FormaterMaxWeigth', label: this.$t('label.maxWeight')+'(TON)', _style: 'text-align:center; width:10%', _classes:'text-center' },
    { key: 'FormaterMaxHeigth', label: this.$t('label.maxRange')+'(MTR)', _style: 'text-align:center; width:10%', _classes:'text-center' },
    { key: 'Operativa', label: this.$t('label.operativa'), _style: 'text-align:center; width:8%', _classes:'text-center'},
    { key: 'UltimoUsuario', label: this.$t('label.user'), _style: 'width:8%'},
    { key: 'FormatedDate', label: this.$t('label.date'), _style: 'text-align:center;width:8%; ', _classes:'text-center'},
    { key: 'Status', label: this.$t('label.status'), _style: 'text-align:center; width:8%;'},
    { 
      key: 'Details', 
      label: '', 
      _style: 'width:50px', 
      sorter: false, 
      filter: false,
    }
  ];
}
//Created:
function created() { }

//Data:
function data() {
  return {
    valid: false,
    isSubmit: false,
    isLoading: false,
    modalAddTpGrua: false,
    isLoadingTpGrua: false,
    items: [],
    grua: {
      VesselCraneId: '',
      VesselId: '',
      TpCraneId: '',
      CraneName: '',
      MaxWeigth: '',
      MaxHeigth: '',
      FgOperational: 1,
      Status: 1
    },
    TpGrua: null,
    tipoGruas: [],
    oldFgAct: false,
    edit: false,
    activePage: 1,

    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
  }
}

//Methods:
function ValidateFormControl(value) {
  value = NumberFormater.setNum(value);
  if (value == 0 && this.valid==true) {
    return 'form-control is-invalid';
  }else{
    if(value == 0 && this.valid==false){
      return 'form-control';
    }else{
      return 'form-control is-valid';
    }
  }
}

function getGruas(IdBuque) {
  this.isLoading = true;
  this.$http.ejecutar('GET', 'VesselCraneList-by-vessel', { VesselId: IdBuque })
  .then(response => {
    this.items = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}
function submit() {
  try{
    this.isSubmit = true;
    this.$v.$touch();
    this.valid = true;
    let MinWeigth = NumberFormater.setNum(this.grua.MaxWeigth) > 0 ? false : true;
    let MinHeigth = NumberFormater.setNum(this.grua.MaxHeigth) > 0 ? false : true;
    if (this.$v.$error || MinWeigth || MinHeigth) {
      throw this.$t('label.errorsPleaseCheck');
    }
    if(!this.edit)
      this.addGrua();
    else
      this.editGrua();
  }catch(e){
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
    
}
function addGrua() {

  let VesselCraneJson = {...this.formatedData()};

  this.$http.ejecutar('POST', 'VesselCrane-insert', VesselCraneJson, { root: 'VesselCraneJson' })
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: response.data.data[0].Response,
      type: "success"
    });
    this.resetInputs();
    this.getGruas(this.buque.VesselId);
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isSubmit = false;
  });
}
function editGrua() {
  
  let VesselCraneJson = {...this.formatedData()};

  this.$http.ejecutar('PUT', 'VesselCrane-update', VesselCraneJson, { root: 'VesselCraneJson' })
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡Exito!',
      text: response.data.data[0].Response,
      type: "success"
    });
    this.resetInputs();
    this.getGruas(this.buque.VesselId);
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isSubmit = false;
  });
}
function resetInputs() {
  this.valid = false;
  if(!this.edit){
    this.grua.CraneName = '';
    this.grua.TpCraneId = ''
    this.grua.FgOperational = 1;
    this.grua.MaxWeigth = 0;
    this.grua.MaxHeigth = 0;
    this.TpGrua = null;
    this.$v.$reset();
  } else{
    this.grua.VesselCraneId = '';
    this.grua.CraneName = '';
    this.grua.TpCraneId = '';
    this.grua.FgOperational = 1;
    this.grua.MaxWeigth = 0;
    this.grua.MaxHeigth = 0;
    this.grua.Status = 1;
    this.TpGrua = null;
    this.edit = false;
    this.$v.$reset();
  }
}
function handleEdit(item) {
  this.grua.VesselCraneId = item.VesselCraneId;
  this.grua.CraneName = item.CraneName;
  this.grua.TpCraneId = item.TpCraneId;
  this.grua.FgOperational = item.FgOperational?1:0;
  this.grua.Status = item.FgActVesselCrane?1:0;
  this.oldFgAct = item.FgActVesselCrane;
  this.edit = true;
  this.TpGrua = {
    TpCraneName: item.TpCraneName,
    TpCraneId: item.TpCraneId,
    MaxWeigth: item.MaxWeigth,
    MaxHeigth: item.MaxHeigth
  };
  this.$v.$touch();
}
function formatedData() {
  if(!this.edit){
    return {
      VesselId: this.grua.VesselId,
      TpCraneId: this.TpGrua.TpCraneId,
      CraneName: this.grua.CraneName,
      MaxWeigth: NumberFormater.setNum(this.grua.MaxWeigth),
      MaxHeigth: NumberFormater.setNum(this.grua.MaxHeigth),
      FgOperational: this.grua.FgOperational
    }
  } else {
    return {
      VesselCraneId: this.grua.VesselCraneId,
      VesselId: this.grua.VesselId,
      TpCraneId: this.TpGrua.TpCraneId,
      CraneName: this.grua.CraneName,
      MaxWeigth: NumberFormater.setNum(this.grua.MaxWeigth),
      MaxHeigth: NumberFormater.setNum(this.grua.MaxHeigth),
      FgOperational: this.grua.FgOperational,
      Status: this.grua.Status,
    };
  }
}
function statusConfirmation(){
  if(!this.edit)
    this.submit();
  else{
    let status = this.oldFgAct?1:0;
    if(this.grua.Status === status)
      this.submit();
    else{
      this.$swal.fire({
        text: `${this.$t('label.changeStatusQuestion')+' '} ${this.grua.CraneName}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#42AA91',
        cancelButtonColor: '#E1373F',
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          this.submit();
        }
      });
    }
  }
}
function toggleTpGruaAdd() {
  this.modalAddTpGrua = true;
}
function handleTpGruaSubmit() {
  this.getTpGruas();
}
function getTpGruas() {
  this.isLoadingTpGrua = true;
  this.$http.ejecutar('GET', 'TpCrane-list', { Filter: 'ACTIVO' })
  .then(response => {
    this.tipoGruas = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingTpGrua = false;
  });
}

//Computerd: 
function buqueIMO() {
  return this.buque && this.buque.VesselName && this.buque.Imo
    ?`${this.buque.VesselName} - ${this.buque.Imo}`
    :'';
}
function formatedGrua() {
  return this.items.map((item) => Object.assign({}, item, {
    FormaterMaxWeigth: NumberFormater.formatNumber(item.MaxWeigth, 2),
    FormaterMaxHeigth: NumberFormater.formatNumber(item.MaxHeigth, 2),
    RowNumber: item.Nro,
    TpGrua: item.TpCraneName,
    Operativa: item.FgOperational?'SI':'NO',
    UltimoUsuario: item.TransaLogin? item.TransaLogin:'N/A',
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
}
function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}
function statusOptions() {
  return [
    {
      value: 1,
      label: this.$t('label.ACTIVO')
    },
    {
      value: 0,
      label: this.$t('label.INACTIVO')
    }
  ]
}
function operativaOptions() {
  return [
    {
      value: 1,
      label: this.$t('label.yes')
    },
    {
      value: 0,
      label: 'NO'
    }
  ]
}
function statusSelectColor(){
  return this.grua.Status === 1;
}
function submitText() {
  return this.edit?this.$t('label.edit'):this.$t('label.add');
}
function isTpGruasEmpty(){
  return this.tipoGruas.length == 0;
}
function tpGruasOptions() {
  return this.tipoGruas.map((tpGrua) => Object.assign({}, tpGrua, {
    label: tpGrua.TpCraneName,
    value: tpGrua.TpCraneId
  }));
}
function isTpGruaValid() {
  return {
    'invalid-feedback': true,
    invalid: this.TpGrua? null : this.$v.$anyDirty? this.$v.TpGrua.$invalid : null
  };
}

export default {
  name: 'buque-grua',
  mixins: [GeneralMixin],
  props: {
    buque: {
      type: Object,
      required: true,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Money,
    AddTpGruaModal
  },
  data,
  created,
  validations: BuqueGruaValidations,
  directives: UpperCase,
  computed: {
    buqueIMO,
    formatedGrua,
    isSubmitValid,
    statusOptions,
    submitText,
    isTpGruasEmpty,
    isTpGruaValid,
    tpGruasOptions,
    operativaOptions,
    fields,
  },
  methods: {
    ValidateFormControl,
    addGrua,
    editGrua,
    resetInputs,
    submit,
    handleEdit,
    getGruas,
    formatedData,
    statusSelectColor,
    statusConfirmation,
    toggleTpGruaAdd,
    handleTpGruaSubmit,
    getTpGruas
  },
  watch: {
    buque: function(newBuque) {
      if( Object.keys(newBuque).length != 0 && newBuque.VesselId) {
        this.getTpGruas();
        this.getGruas(newBuque.VesselId);
        this.grua.VesselId = newBuque.VesselId;
      }
    },
    TpGrua: function(newTpGrua) {
      if(newTpGrua){
        this.grua.MaxWeigth = newTpGrua.MaxWeigth;
        this.grua.MaxHeigth = newTpGrua.MaxHeigth;
      }
    },
    active: function(newVal) {
      if(newVal) {
        if( Object.keys(this.buque).length != 0 && this.buque.VesselId) {
          this.getTpGruas();
          this.getGruas(this.buque.VesselId);
          this.grua.VesselId = this.buque.VesselId;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-adjust {
  flex: 1 1 auto;
  margin-bottom: 0;
}
.vs--searchable .vs__dropdown-toggle {
  height: 100%;
}
.center-cell {
  text-align: center;
}
 
</style>