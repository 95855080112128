var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',{class:{
  'manifest-card': _vm.manifest ? true : null,
  'pdf-manifest-card': _vm.manifestPdf ? true : null
}},[(!_vm.pdf)?_c('loading-overlay',{attrs:{"active":_vm.isLoading,"is-full-page":true,"loader":"bars"}}):_vm._e(),_c('CCardBody',{class:{'pdf-manifest-card-body': _vm.manifestPdf ? true : null}},[_c('CRow',[(_vm.ValidateContainerShipVessel)?_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"transversal"},[_c('div',{staticClass:"vista",class:_vm.getPdfClass},[_c('img',{attrs:{"src":_vm.containerShipImg,"alt":""}}),_c('div',{staticClass:"top-half"},[_c('transversal-row',{attrs:{"items":_vm.formatedToDeck,"showCode":"","onlyNumbers":"","tooltip":_vm.tooltip}}),_c('transversal-row',{attrs:{"items":_vm.formatedToDeck,"pdf":_vm.pdf,"tooltip":_vm.tooltip}})],1),_c('div',{staticClass:"bottom-half"},[_c('transversal-row',{attrs:{"items":_vm.formatedUnderDeck,"alignTop":"","pdf":_vm.pdf,"tooltip":_vm.tooltip}})],1)])])]):(_vm.ValidateGeneralCargoVessel || _vm.ValidateBulkVessel || _vm.manifest || _vm.manifestPdf)?_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"transversal"},[_c('div',{staticClass:"vista",class:_vm.getPdfClass},[_c('img',{attrs:{"src":_vm.bulkCarrierImg,"alt":""}}),_c('div',{staticClass:"general-top-half",class:{
                'manifest-general-top-half': _vm.manifest ? true : null,
                'manifestPdf-general-top-half': _vm.manifestPdf ? true : null
              }},_vm._l((_vm.getCranes),function(crane,i){return _c('div',{key:i,staticClass:"hold-wrapper no-bg",style:({ width: `calc( calc(100% / ${_vm.holdsQty}) )` })},[(crane.FgCrane)?_c('img',{class:_vm.ValidateGeneralCargoVessel && _vm.CantCrane <= 2 ? _vm.CraneGeneralCargoClasses(crane.Crane) : _vm.craneClasses(crane.Crane),attrs:{"src":_vm.craneBulkImg,"alt":""}}):_vm._e()])}),0),_c('div',{staticClass:"general-bottom-half",class:{
                'manifest-general-bottom-half': _vm.manifest ? true : null,
                'manifestPdf-general-bottom-half': _vm.manifestPdf ? true : null
              }},_vm._l((_vm.getHolds),function(hold,i){return _c('div',{key:i,staticClass:"hold-wrapper",class:_vm.getPdfFont,style:({ width: `calc( calc(100% / ${_vm.holdsQty}) )` })},[(hold.FgHold && _vm.ValidateBulkVessel)?_c('div',{staticClass:"hold-name"},[_vm._v(" "+_vm._s(hold.PositionJson[0].VesselHoldName)+" ")]):_vm._e(),(hold.FgHold && _vm.ValidateBulkVessel)?_c('img',{staticClass:"hold-hatch",class:{
                    'manifest-hold-hatch': _vm.manifest ? true : null,
                    'manifestPdf-hold-hatch': _vm.manifest ? true : null
                  },attrs:{"src":"/img/BUQUES/SVG/tapa2.svg","alt":""}}):_vm._e(),(_vm.ValidateGeneralCargoVessel)?_c('div',{staticClass:"hold-hatch-GeneralCargo"},[_vm._v(" "+_vm._s(hold.PositionJson[0].VesselHoldName)+" ")]):_vm._e(),(hold.FgHold && _vm.manifestPdf)?_c('div',{staticClass:"manifest-occupied"},[_vm._v(" "+_vm._s(`${_vm.formatNumber(hold.PositionJson[0].OccupiedTon)} TON`)+" ")]):_vm._e(),(hold.FgHold && !_vm.manifestPdf)?_c('div',{staticClass:"hold-info"},[(_vm.ValidateBulkVessel)?_c('span',{class:{'bg-manifestPdf-hold': _vm.manifestPdf ? true : null}},[_vm._v(" "+_vm._s(`${_vm.formatNumber(hold.PositionJson[0].Bale)} ${_vm.manifest || _vm.manifestPdf ?'M³':'CBM'}`)+" ")]):_vm._e(),(_vm.ValidateGeneralCargoVessel)?_c('span',{class:{'bg-manifestPdf-hold': _vm.manifestPdf ? true : null}},[_vm._v(" "+_vm._s(`${_vm.formatNumber(hold.PositionJson[0].Volumen)} M³`)+" ")]):_vm._e()]):_vm._e(),((_vm.manifest && hold.FgHold)||(_vm.manifestPdf && hold.FgHold))?_c('div',{staticClass:"hold-manifest",class:{'hold-manifest-pdf': _vm.manifestPdf ? true : null}},_vm._l((hold.PositionJson[0].BlJson),function(bl){return _c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: `${bl.Porc}`.replace('.', ',') + '%',
                      placement: 'top-center',
                    }),expression:"{\n                      content: `${bl.Porc}`.replace('.', ',') + '%',\n                      placement: 'top-center',\n                    }"}],key:bl.StowagePlanningBlHoldId,staticClass:"bl-item",style:({
                      width: `100%`,
                      height: `${bl.Porc}%`,
                      backgroundColor: `${_vm.checkBg(bl) ? bl.Color : ''}`
                    }),on:{"click":function($event){$event.stopPropagation();return _vm.selectHold(hold)}}})}),0):_vm._e()])}),0)])])]):_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"vessel-grid",style:(_vm.vesselBackgroundStyles)})])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }