<template>
  <CCard :class="{
    'manifest-card': manifest ? true : null,
    'pdf-manifest-card': manifestPdf ? true : null
  }">
    <loading-overlay :active="isLoading" :is-full-page="true" loader="bars" v-if="!pdf" />
    <CCardBody :class="{'pdf-manifest-card-body': manifestPdf ? true : null}">
      <CRow>
        <CCol sm="12" v-if="ValidateContainerShipVessel">
          <div class="transversal">
            <div class="vista" :class="getPdfClass">
              <img :src="containerShipImg" alt="" />
              <div class="top-half">
                <transversal-row 
                  :items="formatedToDeck"
                  showCode
                  onlyNumbers
                  :tooltip="tooltip"
                />
                <transversal-row 
                  :items="formatedToDeck"
                  :pdf="pdf"
                  :tooltip="tooltip"
                />
              </div>
              <div class="bottom-half">
                <transversal-row 
                  :items="formatedUnderDeck"
                  alignTop
                  :pdf="pdf"
                  :tooltip="tooltip"
                />
              </div>
            </div>
          </div>
        </CCol>
        <CCol sm="12" v-else-if="ValidateGeneralCargoVessel || ValidateBulkVessel || manifest || manifestPdf">
          <div class="transversal">
            <div class="vista" :class="getPdfClass">
              <img :src="bulkCarrierImg" alt="" />
              <div 
                class="general-top-half"
                :class="{
                  'manifest-general-top-half': manifest ? true : null,
                  'manifestPdf-general-top-half': manifestPdf ? true : null
                }"
              >
                <div
                  class="hold-wrapper no-bg"
                  :style="{ width: `calc( calc(100% / ${holdsQty}) )` }"
                   v-for="(crane, i) in getCranes"
                  :key="i"
                >
                  <img
                    :src="craneBulkImg"
                    :class="ValidateGeneralCargoVessel && CantCrane <= 2 ? CraneGeneralCargoClasses(crane.Crane) : craneClasses(crane.Crane)"
                    v-if="crane.FgCrane"
                    alt=""
                  >
                </div>
              </div>
              <div 
                class="general-bottom-half" 
                :class="{
                  'manifest-general-bottom-half': manifest ? true : null,
                  'manifestPdf-general-bottom-half': manifestPdf ? true : null
                }"
              >
                <div
                  class="hold-wrapper"
                  :class="getPdfFont"
                  :style="{ width: `calc( calc(100% / ${holdsQty}) )` }"
                   v-for="(hold, i) in getHolds"
                  :key="i"
                >
                  <div
                    class="hold-name"
                    v-if="hold.FgHold && ValidateBulkVessel"
                  >
                      {{ hold.PositionJson[0].VesselHoldName }}
                  </div>
                   <img
                    src="/img/BUQUES/SVG/tapa2.svg"
                    class="hold-hatch"
                    :class="{
                      'manifest-hold-hatch': manifest ? true : null,
                      'manifestPdf-hold-hatch': manifest ? true : null
                    }"
                    v-if="hold.FgHold && ValidateBulkVessel"
                    alt=""
                  >

                  <div v-if="ValidateGeneralCargoVessel" class="hold-hatch-GeneralCargo">
                    {{ hold.PositionJson[0].VesselHoldName }}
                  </div>

                  <div
                    class="manifest-occupied"
                    v-if="hold.FgHold && manifestPdf"
                  >
                      {{ `${formatNumber(hold.PositionJson[0].OccupiedTon)} TON` }}
                  </div>
                  <div class="hold-info" v-if="hold.FgHold && !manifestPdf">
                    <span :class="{'bg-manifestPdf-hold': manifestPdf ? true : null}" v-if="ValidateBulkVessel">
                      {{ `${formatNumber(hold.PositionJson[0].Bale)} ${manifest || manifestPdf ?'M³':'CBM'}` }}
                    </span>
                    <span :class="{'bg-manifestPdf-hold': manifestPdf ? true : null}" v-if="ValidateGeneralCargoVessel">
                      {{ `${formatNumber(hold.PositionJson[0].Volumen)} M³` }}
                    </span>
                  </div>
                  <div
                    class="hold-manifest"
                    :class="{'hold-manifest-pdf': manifestPdf ? true : null}"
                    v-if="(manifest && hold.FgHold)||(manifestPdf && hold.FgHold)"
                  >
                    <div
                      v-for="bl in hold.PositionJson[0].BlJson"
                      :key="bl.StowagePlanningBlHoldId"
                      class="bl-item"
                      :style="{
                        width: `100%`,
                        height: `${bl.Porc}%`,
                        backgroundColor: `${checkBg(bl) ? bl.Color : ''}`
                      }"
                      v-c-tooltip="{
                        content: `${bl.Porc}`.replace('.', ',') + '%',
                        placement: 'top-center',
                      }"
                      @click.stop="selectHold(hold)"
                    >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CCol>
        <CCol sm="12" v-else>
          <div
            class="vessel-grid"
            :style="vesselBackgroundStyles"
          >
          </div>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapState } from 'vuex';
import TransversalRow from "@/pages/buque/bays/transversal-row";
import { GenerateSvgPlaceholder, NumberFormater } from '@/_helpers/funciones';
import TransversalSrc from '@/_mixins/transversalViewSrc';

//Data
function data() {
  return {
    isLoading: false,
    baysJson: [],
    generalJson: [],
    holdsQty: 0,
    errorMsgDone: false,
  }
}

//Created:
function created() {
  this.errorMsgDone = false;
  if(this.VesselId)
    this.getDataFromService();

  if(this.billOfLading.length != 0 && this.manifestPdf) {
    
    this.generalJson = this.billOfLading;
    this.holdsQty = 0;
  }
}

//Metodos: 
function getDataFromService() {
  this.isLoading = true;

  this.$http.ejecutar('GET', 'VesselTransversalView-by-vesselA', { VesselId: this.VesselId })
  .then(response => {
    switch (this.VesselType.toUpperCase()) {
      case process.env.VUE_APP_CONTAINER_SHIP_VESSEL_ID:
        this.baysJson = response.data.data || [];
        break;
      case process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID:
      case process.env.VUE_APP_BULK_VESSEL_ID:
        this.generalJson = response.data.data || [];
        this.holdsQty = this.getHoldsCount(this.generalJson);
        break;
      default:
        this.baysJson = [];
        this.generalJson = [];
        this.holdsQty = this.getHoldsCount(this.generalJson);
        break;
    }
    if(this.pdf) {
      this.$nextTick(() => {
        window.print();
      });
    }
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}
function generateTiersArray(tierFrom, tierTo, bayCode) {
  if(!bayCode) return [];

  let arr = [],
    end = Number.parseInt(tierFrom, 10),
    start = Number.parseInt(tierTo, 10);

  for (let index = start; index >= end; index--) {
    if (Math.abs(index % 2) === 0) {
      arr.push({
        BayCode: bayCode,
        Tier: index < 10 ? `0${index}` : `${index}`,
        Code: `${bayCode}-${index}`,
      });
    }
  }
  return arr;
}
function getHoldsCount(arr) {
  if(arr.length == 0) return 0;

  let count = 0;
  for (let index = 0; index < arr.length; index++) {
    if(arr[index].FgHold)
      count++;
  }

  return count;
}

function formatNumber(number) {
  return NumberFormater.formatNumber(number, 2);
}

function selectHold(hold) {
  this.$emit("open-hold", hold);
}

function craneClasses(crane){
  if(this.manifestPdf) {
    return {
      'grua-general': true,
      'manifestPdf-crane-to-popa': crane.FgStern ? true : null,
      'manifestPdf-crane-to-proa': crane.FgBow ? true : null,
      'grayscale-crane': !crane.FgActive ? true : null,
    }
  }
  
  return {
    'grua-general': true,
    'crane-to-popa': crane.FgStern ? true : null,
    'crane-to-proa': crane.FgBow ? true : null,
    'grayscale-crane': !crane.FgActive ? true : null,
  }
}
function CraneGeneralCargoClasses(crane) {
  return {
    'crane-to-popa-GeneralCargo': crane.FgStern ? true : null,
    'crane-to-proa-GeneralCargo': crane.FgBow ? true : null,
  }
}
function checkBg(bl){
  if(!this.manifestPdf) return true; 
  if(bl.StowagePlanningBlHoldId) return true;
  else return false;
}
function currentOccupied(value) {
  return GenerateSvgPlaceholder({
    width: 150,
    height: 50,
    bgColor: 'transparent',
    textColor: '#fff',
    text: '2.222.222,22 TON'
  });
}

//Computeds: 
function formatedToDeck() {
  return this.baysJson.map((bay) => {
      if(bay.BayCode && bay.FgBay) {
        return Object.assign({}, {
          BayCode: bay.BayCode,
          FgCrane: bay.FgCrane,
          Tiers: bay.PositionJson[0].ToDeckJson.length == 1 ? this.generateTiersArray(
            bay.PositionJson[0].ToDeckJson[0].TierToDeckFrom,
            bay.PositionJson[0].ToDeckJson[0].TierToDeckTo,
            bay.BayCode,
          ) : [],
        });
      } else if(!bay.BayCode && bay.FgCrane) {
        return Object.assign({}, {
          BayCode: bay.BayCode,
          FgCrane: bay.FgCrane,
          Tiers: [],
        });
      }
  });
}
function formatedUnderDeck() {
  return this.baysJson.map((bay) => {
      if(bay.BayCode && bay.FgBay) {
        return Object.assign({}, {
          BayCode: bay.BayCode,
          Tiers: bay.PositionJson[0].UnderDeckJson.length == 1 ? this.generateTiersArray(
            bay.PositionJson[0].UnderDeckJson[0].TierUnderDeckFrom,
            bay.PositionJson[0].UnderDeckJson[0].TierUnderDeckTo,
            bay.BayCode,
          ) : [],
        });
      } else if(!bay.BayCode && bay.FgCrane) {
        return Object.assign({}, {
          BayCode: bay.BayCode,
          Tiers: [],
        });
      }
  });
}
function vesselBackgroundStyles() {
  return { 
    backgroundImage: `url('${this.fondo}')`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
  };
}
function fondo(){
  if(!this.VesselType){
    return '/img/TransversalVesselView.svg';
  } else {
    switch(this.VesselType.toUpperCase()){
      case process.env.VUE_APP_CONTAINER_SHIP_VESSEL_ID:
        return '/img/BUQUES/SVG/ContainerShip.svg';
      case process.env.VUE_APP_BULK_VESSEL_ID:
        return '/img/BUQUES/SVG/BulkCarrier.svg';
      case process.env.VUE_APP_CHEMICAL_TANKERS_VESSEL_ID:
        return '/img/BUQUES/SVG/ChemicalTanker.svg';
      case process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID:
        return '/img/BUQUES/SVG/GeneralCargo.svg';
      case 'L.N.G. CARRIER':
        return '/img/BUQUES/SVG/LNLCarrier.svg';
      case process.env.VUE_APP_CRUDE_OIL_CARRIERSP_VESSEL_ID:
        return '/img/BUQUES/SVG/oilTanker.svg';
      default:
        return '';
    } 
  }
}
function getHolds() {
  return this.generalJson.filter((item) => item.FgHold == true);
}
function getCranes() {
  let arr = [];
  for (let index = 0; index < this.generalJson.length; index++) {
    if(this.generalJson[index].FgHold){
      if (index-1 >= 0 &&!arr[index-1]?.FgHold&&!arr[index-1]?.Duplicate) {
        arr[index-1] = {
          ...arr[index-1],
          ...this.generalJson[index],
          FgCrane: true,
        };
        arr.push({
          Duplicate: true,
        });
      }else{
        arr.push(this.generalJson[index]);
      }
    }else{
      if(this.generalJson[index].FgCrane){
        if(!this.generalJson[index].PositionJson) {
          if(!this.errorMsgDone && !this.pdf){
            this.$notify({
              group: 'container',
              title: '¡Error!',
              text: `${this.$t('label.CraneErrorTransversalView')}`,
              type: "error"
            });
            this.errorMsgDone = true;
          }
        } else {
          if(index-1 >= 0 && !arr[index-1]?.Duplicate){
            arr[index-1].Crane = {...this.generalJson[index].PositionJson[0]};
            arr[index-1].FgCrane = true;
            arr.push({
              Duplicate: true,
            });
          }else{
            arr.push({
              ...this.generalJson[index],
              Crane: {...this.generalJson[index].PositionJson[0]},
              PositionJson: [],
            });
          }
        }
      }
    }
  }
  return arr.filter((item) => item.FgHold == true);
}
function getPdfClass() {
  return {
    'pdf-transversal': this.pdf ? true : null,
    'manifest-vista': this.manifest ? true : null,
    'pdf-manifest-transversal': this.manifestPdf ? true : null,
  }
}

function getPdfFont() {
  return {
    'pdf-transversal-fuente': this.pdf ? true : null,
  }
}

function CantCrane() {
  return this.getCranes.filter((item) => item.FgCrane).length;
}

function getCurrentBillOfLading() {
  return this.billOfLading.length;
}

function ValidateContainerShipVessel() {
  return this.VesselType.toUpperCase() == process.env.VUE_APP_CONTAINER_SHIP_VESSEL_ID;
}

function ValidateBulkVessel() {
  return this.VesselType.toUpperCase() == process.env.VUE_APP_BULK_VESSEL_ID;
}

function ValidateGeneralCargoVessel() {
  return this.VesselType.toUpperCase() == process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID;
}

export default {
  name: 'transverla-view',
  mixins: [TransversalSrc],
  components: {
    TransversalRow
  },
  props: {
    VesselId: {
      type: String,
      default: ''
    },
    VesselType:{
      type: String,
      default: ''
    },
    pdf: {
      type: Boolean,
      default: false
    },
    plan: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: Boolean,
      default: true
    },
    //Manifiesto de carga
    billOfLading: {
      type: Array,
      default: () => [],
    },
    manifest: {
      type: Boolean,
      default: false
    },
    manifestPdf: {
      type: Boolean,
      default: false
    },
  },
  data,
  created,
  methods: {
    getDataFromService,
    generateTiersArray,
    getHoldsCount,
    formatNumber,
    selectHold,
    craneClasses,
    CraneGeneralCargoClasses,
    checkBg,
    currentOccupied,
  },
  computed: {
    formatedToDeck,
    formatedUnderDeck,
    vesselBackgroundStyles,
    fondo,
    getHolds,
    getCranes,
    getPdfClass,
    getPdfFont,
    CantCrane,
    getCurrentBillOfLading,
    ValidateContainerShipVessel,
    ValidateBulkVessel,
    ValidateGeneralCargoVessel,
    ...mapState({
      currentBay: state => state.baygrid.bay,
    })
  },
  watch: {
    VesselId: function(newId) {
      this.errorMsgDone = false;
      if(newId)
        this.getDataFromService();
    },
    billOfLading: {
      handler: function (val, oldVal) {
        this.errorMsgDone = false;
        this.generalJson = this.billOfLading;
        this.holdsQty = this.getHoldsCount(this.generalJson);
      },
      deep: true
    },
    
  }
}
</script>

<style scoped>
.transversal {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.transversal .vista {
  width:1200px;
  height: 400px;
  position: relative;
}
.transversal .vista img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.transversal .vista .top-half { /*IMPORTANT*/
  position:absolute;
  top: 0;
  bottom: 34%;
  left: 22%;
  right: 11%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.transversal .vista .bottom-half { /*IMPORTANT*/
  position:absolute;
  top: 65%;
  bottom: 4%;
  left: 22%;
  right: 11%;
  display: flex;
  flex-direction: column;
}

.vessel-grid {
  width: 100%;
  height: 400px;

  display: grid;
  grid-template-columns: 0.34fr 1fr 0.18fr;
  grid-template-rows: 0.9fr 1fr 28%;
  gap: 8px 0;
}

.transversal .vista .general-top-half { /*IMPORTANT*/
  position:absolute;
  top: 37%;
  bottom: 34%;
  left: 22%;
  right: 11%;
  display: flex;
  flex-direction: row-reverse;
}
.transversal .vista .general-bottom-half { /*IMPORTANT*/
  position:absolute;
  top: 66%;
  bottom: 7%;
  left: 22%;
  right: 11%;
  display: flex;
  flex-direction: row-reverse;
}

/* Carga General */
.hold-wrapper {
  position: relative;
  width: calc(calc(100% / 2));
  border: 1px solid #000;
  background-color: #fff;
}
.hold-wrapper .hold-name {
  /* border: 1px solid #000; */
  /* padding: 2px; */
  /* background-color: #fff; */
  text-align: center;
  position: absolute;
  top: -30%;
  width: 70%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 10px;
  color: #000;
}
.hold-wrapper .hold-info {
  text-align: center;
  padding: 2px;
  border-bottom: 1px solid #000;
}

.hold-wrapper img.grua-general {
  /* border: 1px solid #000; */
  position: absolute;
  width: 90%;
  /* bottom: -1%; */
  /* left: -14.5%; */
  left: -11.5%;
}
.no-bg {
  background-color: transparent;
  border: none;
}

/* Sizes */
.pdf-transversal {
  width: 985px !important;
  height: 327px !important;
}
.pdf-transversal-fuente {
  font-size: 0.75rem !important;
}

/* Manifest */
.manifest-vista {
  width:1075px !important;
}
.manifest-general-top-half {
  bottom: 35% !important;
  top: 37% !important;
}
.manifest-general-bottom-half {
  top: 64.2% !important;
  bottom: 11.4% !important;
}
.manifest-hold-name {
  top: -15% !important;
}
.manifest-card{ 
  border: none !important;
}
.hold-manifest {
  width: 100%;
  height: 74%;
  display: flex;
  flex-direction: column-reverse;
}
.bl-item {
  cursor: pointer;
}
.crane-to-popa {
  transform: scaleX(-1);
  left: -79% !important;
}
.crane-to-proa {
  left: -11.5% !important;
}
.crane-to-proa-GeneralCargo {
  transform: scaleX(-1);
  left: -25% !important;
}
.crane-to-popa-GeneralCargo {
  left: 25% !important;
}
.manifestPdf-crane-to-popa {
  transform: scaleX(-1);
  left: -78.1% !important;
}
.manifestPdf-crane-to-proa {
  left: -12% !important;
}
.manifestPdf-general-top-half{
  bottom: 32% !important;
}
.manifestPdf-general-bottom-half{
  bottom: 2.3% !important;
  top: 67% !important;
}
.hold-manifest-pdf{
  height: 73% !important;
}
.pdf-manifest-card {
  border: none !important;
  margin-bottom: 0 !important;
}
.pdf-manifest-card-body {
  padding: 0 !important;
}
.pdf-manifest-transversal {
  width: 870px !important;
  height: 265px !important;
}
.not-overflow {
  overflow: hidden;
}
.manifest-occupied{ 
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 85%;
  width: 70%;
  text-align: center;
  background-color: #002060;
  color: #fff;
  font-weight: bold;
  font-size: 10px;
  padding: 2px 0;
}
.hold-hatch {
  position: absolute;
  top: -10% !important;
  width: 90% !important;
  height: 25% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.hold-hatch-GeneralCargo {
  border: 1px solid;
  background: #ffffff;
  position: absolute;
  text-align: center;
  top: -10% !important;
  width: 90% !important;
  height: 19% !important;
  left: 50% !important;
  transform: translate(-50%,-50%);
  font-weight: 700;
  font-size: 10px;
  color: #000;
}
.bg-manifestPdf-hold{
  background-color: #ff9933;
  color: #000 !important;
}
.grayscale-crane {
  filter: invert(50%) grayscale(1) brightness(.8);
}
</style>