import { 
  imgInputTextsHelpers,
  errorMessageHelpers
} from '@/_helpers/funciones';

//Data:
function data() {
  return {  }
}

//Meodos:
function hasError(field){
  return field.$dirty ? !field.$error : null;
}
function errorMessage(field){
  return errorMessageHelpers(this, field);
}

//Computeds
function isSubmitValid(){
  return this.$v.$invalid || this.isSubmit;
}
function imgInputTexts(){
  return imgInputTextsHelpers(this);
}

export default {
  data,
  methods: {
    hasError,
    errorMessage
  },
  computed: {
    isSubmitValid,
    imgInputTexts,
  }
}