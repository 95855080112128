<template>
<div>
  <loading-overlay :active="isLoadingGeneral" :is-full-page="true" loader="bars" />
  <form @submit.stop.prevent="submit" class="company-form">
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader style="position: relative;">
             <CRow>
                <CCol sm="12" lg="12">
                  {{ formTittle }}
                </CCol>
                <div class="d-flex justify-content-end position-dropdown">
                  <img size="sm" width="30px" height="30px" 
                      class="position-icon"
                      style="cursor: pointer;"
                      src="/img/iconos/GIF_AYUDA.gif"
                      @click="EventImg()"
                    >
                    <CDropdown id="dropdown-help" addTogglerClasses="Toggler-Botton p-1" 
                       :togglerText="''" style="width: 30px; height: 30px;">
                      <CDropdownItem>
                        <a class="dropdown-item" @click="OpenModal(1,$t('label.listVessel'))">{{$t('label.listVessel')}}</a>
                      </CDropdownItem>
                      <CDropdownItem>
                        <a class="dropdown-item" @click="OpenModal(2,$t('label.hold')+'S')">{{$t('label.hold')+'S'}}</a>
                      </CDropdownItem>
                      <CDropdownItem>
                        <a class="dropdown-item" @click="OpenModal(3,$t('label.crane'))">{{$t('label.crane')}}</a>
                      </CDropdownItem>
                      <CDropdownItem>
                        <a class="dropdown-item" @click="OpenModal(4,$t('label.crane')+'S-'+$t('label.hold')+'S')">{{$t('label.crane')+'S-'+$t('label.hold')+'S'}}</a>
                      </CDropdownItem>
                      <CDropdownItem v-if="hasGrua">
                        <a class="dropdown-item" @click="OpenModal(5,$t('label.bay'))">{{$t('label.bay')}}</a>
                      </CDropdownItem>
                      <CDropdownItem v-if="hasGrua">
                        <a class="dropdown-item" @click="OpenModal(6,$t('label.crane')+'S-'+$t('label.bay')+'S')">{{$t('label.crane')+'S-'+$t('label.bay')+'S'}}</a>
                      </CDropdownItem>
                      <CDropdownItem>
                        <a class="dropdown-item" @click="OpenModal(7,$t('label.transversalView'))">{{$t('label.transversalView')}}</a> 
                      </CDropdownItem>
                    </CDropdown>
                </div>
             </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <CustomTabs :active-tab="activeTab" @update:activeTab="handleTab">
                  <CustomTab :title="$t('label.vesselInfo')" iconSrc="/img/iconos/Tab-Datos-Buque.svg">
                    <CRow align-vertical="center" class="mb-4 mt-4">
                      <CCol sm="4" >
                        <div class="preview">    
                          <picture-input
                            v-if="true"
                            ref="imageInput"
                            :imgSrc="image"
                            width="500"
                            height="250"
                            :zIndex="1000"
                            accept="image/jpeg,image/png"
                            size="10"
                            :custom-strings="imgInputTexts"
                            @change="handleFileUpload"
                          >
                          </picture-input>
                        </div>
                      </CCol>
                      <CCol sm="5">
                        <CRow>
                          <CCol sm="12">
                            <div class="position-relative">
                              <div class="form-group form-row" rol="group">
                                <label class="required text-right col-form-label col-sm-3 col-form-label-sm" for="tpBuque">{{$t('label.vesselType')}}</label>
                                <div class="col-sm-9 input-group">
                                  <v-select 
                                    :options="tpBuquesOptions"
                                    label="TpVesselName"
                                    :placeholder="$t('label.select')"
                                    class="select-adjust"
                                    v-model="tpBuque"
                                    :disabled="isTpBuquesEmpty"
                                  >
                                    <template #list-header>
                                      <option @click.stop="toggleTpBuqueAdd"> <strong>{{$t('label.nuevo')+' '+$t('label.type')}}</strong></option>
                                    </template>
                                  </v-select>
                                  
                                  <div :class="isTpBuqueValid">
                                    {{$t('label.required')}}
                                  </div>
                                </div>
                              </div>
                              <CElementCover
                                :opacity="0.8"
                                v-show="isLoadingTpBuque"
                              >
                                <label class="d-inline">{{$t('label.load')}}... </label>
                                <CSpinner size="sm" />
                              </CElementCover>
                            </div>
                          </CCol>
                          <CCol sm="12">
                            <div class="form-group form-row" rol="group">
                              <label class="required text-right col-form-label col-sm-3 col-form-label-sm" for="pais">{{$t('label.country')}}</label>
                              <div class="col-sm-9 input-group-sm">
                                <v-select 
                                  :filter="fuseSearchPais"
                                  :options="paisOptions"
                                  label="CountryName"
                                  :placeholder="$t('label.select')"
                                  v-model="pais"
                                  :disabled="isPaisesEmpty"
                                />
                                <div :class="isPaisValid">
                                  {{$t('label.required')}}
                                </div>
                              </div>
                            </div>
                          </CCol>
                          <CCol sm="12">
                            <CInput
                              v-model.trim="$v.buque.VesselName.$model"
                              v-uppercase
                              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
                              addLabelClasses="text-right required"
                              :invalid-feedback="errorMessage($v.buque.VesselName)"
                              :is-valid="hasError($v.buque.VesselName)"
                              size="sm"
                              :label="$t('label.vesselName')"
                              maxlength="100"
                            >
                            </CInput>
                          </CCol>
                          
                        </CRow>
                      </CCol>
                      <CCol sm="3">
                        <CRow>
                          <CCol sm="12">
                            <CInput
                              v-model.trim="$v.buque.Imo.$model"
                              v-uppercase
                              :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                              addLabelClasses="text-right required"
                              :invalid-feedback="$t('label.required')+': IMO#######'"
                              :is-valid="hasError($v.buque.Imo)"
                              size="sm"
                              :label="$t('label.IMO')"
                              maxlength="10"
                            />
                          </CCol>
                          <CCol sm="12">
                            <CInput
                              v-model.trim="$v.buque.Mmsi.$model"
                              v-uppercase
                              :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                              addLabelClasses="text-right required"
                              :invalid-feedback="errorMessage($v.buque.Mmsi)"
                              :is-valid="hasError($v.buque.Mmsi)"
                              size="sm"
                              label="MMSI"
                              maxlength="9"
                            />
                          </CCol>
                          <CCol sm="12">
                            <CInput
                              v-model.trim="$v.buque.CallSign.$model"
                              v-uppercase
                              :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                              addLabelClasses="text-right required"
                              :invalid-feedback="errorMessage($v.buque.CallSign)"
                              :is-valid="hasError($v.buque.CallSign)"
                              size="sm"
                              label="CALL SIGN"
                              maxlength="10"
                            />
                          </CCol>
                        </CRow>  
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol sm="4"> 
                        <CInput
                          v-model.trim="$v.buque.Loa.$model"
                          :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                          :invalid-feedback="errorMessage($v.buque.Loa)"
                          :is-valid="hasError($v.buque.Loa)"
                          size="sm"
                          label="LOA"
                          addLabelClasses="text-right required"
                          maxlength="20"
                          placeholder="0"
                        >
                          <template #append>
                            <span class="mt-1 ml-1">(MTR)</span>
                          </template>
                        </CInput>
                        <CInput
                          v-model.trim="$v.buque.DraftBow.$model"
                          :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                          :invalid-feedback="errorMessage($v.buque.DraftBow)"
                          :is-valid="hasError($v.buque.DraftBow)"
                          size="sm"
                          :label="$t('label.bowDraft')"
                          addLabelClasses="text-right required"
                          maxlength="20"
                          placeholder="0"
                        >
                          <template #append>
                            <span class="mt-1 ml-1">(MTR)</span>
                          </template>
                        </CInput>
                        <CInput
                          v-model.trim="$v.buque.SummerDwt.$model"
                          :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                          :invalid-feedback="errorMessage($v.buque.SummerDwt)"
                          :is-valid="hasError($v.buque.SummerDwt)"
                          size="sm"
                          :label="$t('label.summerDWT')"
                          addLabelClasses="text-right"
                          maxlength="20"
                          placeholder="0"
                        >
                          <template #append>
                            <div class="col-sm-1 mt-1 ml-1">&nbsp;&nbsp;</div>
                          </template>
                        </CInput>
                      </CCol>
                      <CCol sm="4">
                        <CInput
                          v-model.trim="$v.buque.Lwl.$model"
                          :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                          :invalid-feedback="errorMessage($v.buque.Lwl)"
                          :is-valid="hasError($v.buque.Lwl)"
                          size="sm"
                          :label="$t('label.LWL')"
                          addLabelClasses="text-right"
                          maxlength="20"
                          placeholder="0"
                        >
                          <template #append>
                            <span class="mt-1 ml-1">(MTR)</span>
                          </template>
                        </CInput>
                        <CInput
                          v-model.trim="$v.buque.DraftStern.$model"
                          :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                          :invalid-feedback="errorMessage($v.buque.DraftStern)"
                          :is-valid="hasError($v.buque.DraftStern)"
                          size="sm"
                          :label="$t('label.sternDraft')"
                          addLabelClasses="text-right required"
                          maxlength="20"
                          placeholder="0"
                        >
                          <template #append>
                            <span class="mt-1 ml-1">(MTR)</span>
                          </template>
                        </CInput>
                        <CSelect
                          :value.sync="buque.VesselCrane"
                          :placeholder="$t('label.select')"
                          size="sm"
                          :label="$t('label.deckCrane')"
                          :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                          :invalid-feedback="errorMessage($v.buque.VesselCrane)"
                          :is-valid="hasError($v.buque.VesselCrane)"
                          :options="fgGruaOptions"
                          addLabelClasses="text-right required"
                          class="mt-1"
                        >
                          <template #append>
                            <div class="col-sm-1 mt-1 ml-1">&nbsp;&nbsp;</div>
                          </template>
                        </CSelect>
                      </CCol>
                      <CCol sm="4">
                        <CInput
                          v-model.trim="$v.buque.Gross.$model"
                          :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                          :invalid-feedback="errorMessage($v.buque.Gross)"
                          :is-valid="hasError($v.buque.Gross)"
                          size="sm"
                          label="GROSS"
                          addLabelClasses="text-right required"
                          maxlength="20"
                          placeholder="0"
                        >
                          <template #append>
                            <span class="mt-1 ml-1">(TON)</span>
                          </template>
                        </CInput>
                        <CInput v-if="isContainerShip||isGeneralCargo"
                          v-model.trim="$v.buque.Teus.$model"
                          :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                          :invalid-feedback="errorMessage($v.buque.Teus)"
                          :is-valid="isTeusValid($v.buque.Teus)"
                          size="sm"
                          label="TEUS"
                          :addLabelClasses="{'text-right': true, 'required': isContainerShip}"
                          maxlength="20"
                          placeholder="0"
                          :disabled="!isContainerShip&&!isGeneralCargo"
                        >
                          <template #append>
                            <div class="col-sm-1 mt-1 ml-1">&nbsp;&nbsp;</div>
                          </template>
                        </CInput>
                        <CInput
                          v-model.trim="$v.buque.FuelOilCapacity.$model"
                          :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                          :invalid-feedback="errorMessage($v.buque.FuelOilCapacity)"
                          :is-valid="hasError($v.buque.FuelOilCapacity)"
                          size="sm"
                          :label="$t('label.fuelOilCapacity')"
                          addLabelClasses="text-right text-adjust"
                          maxlength="20"
                          placeholder="0"
                        >
                          <template #append>
                            <span class="mt-1 ml-1">(M³)</span>
                          </template>
                        </CInput>
                        <CCollapse :show="isEditMode">
                          <CSelect
                            :value.sync="buque.Status"
                            :is-valid="statusSelectColor"
                            size="sm"
                            :label="$t('label.status')"
                            :horizontal="{ label: 'col-sm-4', input: 'col-sm-8'}"
                            :options="statusOptions"
                            addLabelClasses="text-right"
                            class="mt-1"
                          >
                          <template #append>
                            <div class="col-sm-1 mt-1 ml-1">&nbsp;&nbsp;</div>
                          </template>
                          </CSelect>
                        </CCollapse>
                      </CCol>
                    </CRow>
                  </CustomTab>
                  <CustomTab :title="$t('label.hold')+'S'" :disabled="!isSaved" iconSrc="/img/iconos/Tab-Bodega.svg">
                    <buque-bodega :active="isActiveTab(1)" :buque="savedBuque"/>
                  </CustomTab>
                  <CustomTab :title="$t('label.crane')+'S'" :disabled="!hasGrua" iconSrc="/img/iconos/Tab-Grua.svg">
                    <buque-grua v-if="hasGrua" :active="isActiveTab(2) || (isActiveTab(2) && !isContainerShip)" :buque="savedBuque"/>
                  </CustomTab>
                  <CustomTab :title="$t('label.crane')+'S-'+$t('label.hold')+'S'" :disabled="!hasGrua" iconSrc="/img/iconos/Tab-Grua-Bodega.svg">
                    <grua-bodega v-if="hasGrua" :active="isActiveTab(3) || (isActiveTab(3) && !isContainerShip)" :buque="savedBuque"/>
                  </CustomTab>
                  <CustomTab :title="$t('label.bay')" v-if="isContainerShip" :disabled="!isSaved" icon="bay">
                    <buque-bay :active="isActiveTab(5)" :buque="savedBuque"/>
                  </CustomTab>
                  <CustomTab
                    :title="$t('label.crane')+'S-'+$t('label.bay')+'S'"
                    v-if="isContainerShip"
                    :disabled="!hasGrua || !isSaved"
                    iconSrc="/img/iconos/Tab-Grua-Bodega.svg"
                  >
                    <grua-bay v-if="hasGrua" :active="isActiveTab(6)" :buque="savedBuque"/>
                  </CustomTab>
                  <CustomTab :title="$t('label.transversalView')" :disabled="!isSaved" iconSrc="/img/iconos/Tab-Vista-Transversal.svg">
                    <transversal-tab
                      :active="isActiveTab(4) || (isActiveTab(4) && !isContainerShip)"
                      :buque="savedBuque"
                    />
                  </CustomTab>
                </CustomTabs>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CRow>
              <CCol sm="12" class="d-flex justify-content-end align-items-center">
                
                <CButton
                  v-if="canAddDoc"
                  shape="square"
                  color="success"
                  
                  class="d-flex align-items-center mr-2"
                  @click.stop="toggleAddDoc"
                >
                  <CIcon name="cil-note-add"/>
                  <span class="ml-1">{{$t('label.add')+$t('label.documents')}}</span>
                </CButton>
                          
                <CButton
                  color="watch"
                  shape="square"
                  :disabled="isSubmit"
                  class="d-flex align-items-center mr-2"
                  @click.stop="handleInfo"
                  v-if="media.length > 0"
                >
                  <CIcon name="cil-info"/><span class="ml-1">INFO</span>
                </CButton>
                <CButton
                  v-if="isBuqueForm"
                  color="add"
                  shape="square"
                  :disabled="isSubmitValid"
                  class="d-flex align-items-center mr-2"
                  @click.stop="statusConfirmation"
                >
                  <div v-if="!isSubmit">
                    <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
                  </div>
                  <div v-if="isSubmit">
                    <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="ml-1">{{$t('button.accept')}}</span>
                  </div>
                </CButton>
                <CButton
                  v-else
                  color="add"
                  shape="square"
                  class="d-flex align-items-center mr-2"
                  @click.stop="listBuques"
                >
                  <CIcon name="checkAlt"/><span class="ml-1">{{$t('label.finish')}}</span>
                </CButton>
                <CButton
                  shape="square"
                  color="wipe"
                  :disabled="isSubmit"
                  class="d-flex align-items-center"
                  @click.once="cancelForm"
                >
                  <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
                </CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CCol>

      <add-tp-buque-modal :modal.sync="modalAddTpBuque" @submited="handleTpBuqueSubmit"/>
      <add-doc-buque-modal
        :modal.sync="addDocModal"
        :VesselId="docVesselId"
        @submited="handleDocsSubmit"
        :title="titleModal"
      />
      <LightBox
        ref="lightbox"
        :media="media"
        :show-light-box="false"
        :show-thumbs="false"
        :site-loading="'/img/siteloading.gif'"
        v-if="media.length>0"
      >
      </LightBox>
      <ModalHelpViews
        :modal.sync="ShowModal"
        :Type="Type"
        :title="titleHelpModal"
        @close="Close"
      />
    </CRow>         
  </form>
</div>
</template>

<script>
import UpperCase  from '@/_validations/uppercase-directive';
import BuqueValidations from '@/_validations/buque/BuqueFormValidations';
import { GenerateImgPlaceholder, imgInputTextsHelpers } from '@/_helpers/funciones';

import AddTpBuqueModal  from '@/pages/tipo-buque/add-tipo-buque-modal';
import AddDocBuqueModal  from './doc-modal';
import BuqueBodega from './buque-bodega';
import BuqueGrua from './buque-grua';
import GruaBodega from './grua-bodega';
import GruaBay from './grua-bays';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import BuqueBay from '@/pages/buque/bays/index';
import TransversalTab from '@/pages/buque/tabs/transversal-tab';

import Fuse from "fuse.js";
import BuqueMixin from '@/_mixins/buque';
import PictureInput from '@/components/picture-input';
import LightBox from 'vue-image-lightbox'
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';

import ModalHelpViews from './modal-help-views';

//Data
function data() {
  return {
    isSubmit: false,
    isSaved: false,
    activeTab: 0,
    modalAddTpBuque: false,
    isLoadingGeneral: false,
    isLoadingTpBuque: false,
    addDocModal: false,
    docVesselId: '',
    savedBuque: {},
    buque: {
      TpVesselId: '',
      CountryId: '',
      VesselName: '',
      Imo: 'IMO',
      Mmsi: '',
      CallSign: '',
      Loa: '',
      Lwl: '',
      Teus: '',
      Gross: '',
      DraftBow: '',
      DraftStern: '',
      SummerDwt: '',
      VesselCrane: null,
      FuelOilCapacity: '',
      VesselRoute: '',
      Status: 1
    },
    FgActVessel: false,
    tpBuques: [],
    tpBuque: null,
    paises: [],
    pais: null,
    image: '/img/uploadPhotoEs.svg',
    media: [],
    titleModal: '',
    Collapse: false,
    ShowModal: false,
    Type: 0,    
    titleHelpModal: ''
  }
}

//*****Funcion created() del ciclo.
function created() {
  let _lang = this.$i18n.locale;
  this.image = _lang =='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';

  if(this.isEditMode){
    this.buque.VesselRoute = this.VesselImage;
    this.image = this.VesselImage?`${this.$store.getters["connection/getBase"]}${this.VesselImage.replace('public/', '', null, 'i')}`:GenerateImgPlaceholder('');
  }

  this.loadData();
}

//Methods
async function submitFileAdd() {
  this.isSubmit = true;
  if(typeof this.buque.VesselRoute == 'string' && this.buque.VesselRoute.includes('Public/')){
    await this.addBuque();
  }else{
    this.$http.file('Vessel-insert', this.buque.VesselRoute)
      .then(async(response) => {
        this.buque.VesselRoute = response.data.data.files[0].path;
        await this.addBuque();
      }).catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.isSubmit = false;
      });
  }
}
function submitFileEdit() {
  if(this.savedBuque.VesselRoute == this.buque.VesselRoute || typeof this.buque.VesselRoute == 'string')
    this.editBuque();
  else{
    this.isSubmit = true;

    this.$http.file('Vessel-update', this.buque.VesselRoute, this.savedBuque.VesselRoute)
    .then((response) => {
      this.buque.VesselRoute = response.data.data.files[0].path;
      this.editBuque();
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.isSubmit = false;
    });
  }
}
function submit() {
  if(!this.isEditMode && !this.isSaved)
    this.submitFileAdd();
  else
    this.submitFileEdit();
}
async function addBuque(){
  this.isSubmit = true;
  this.formatData();

  let VesselJson = {
    ...this.buque,
    VesselRoute: this.buque.VesselRoute,
  };
  delete VesselJson.Status;

  await this.$http.ejecutar('POST', 'Vessel-insert', VesselJson, { root: 'VesselJson' })
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡Exito!',
      text: response.data.data[0].Response,
      type: "success"
    });
    this.savedBuque = {
      ...this.buque,
      VesselId: response.data.data[0].VesselId,
      VesselRoute: response.data.data[0].VesselRoute,
      TpVesselName: response.data.data[0].TpVesselName
    };

    this.setData(response.data.data[0]);
    this.isSaved = true;
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isSubmit = false;
  });
}
function editBuque() {
  this.isSubmit = true;
  this.formatData();
  
  let VesselJson = {
    ...this.buque,
    VesselId: (this.savedBuque.VesselId !== '') ? this.savedBuque.VesselId : this.VesselId,
    VesselRoute: this.buque.VesselRoute,
  };

  this.$http.ejecutar('PUT', 'Vessel-update', VesselJson, { root: 'VesselJson' })
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡Exito!',
      text: response.data.data[0].Response,
      type: "success"
    });
    this.savedBuque = {...response.data.data[0]};
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isSubmit = false;
  });
}
function formatData() {
  this.buque.TpVesselId = this.tpBuque.TpVesselId;
  this.buque.CountryId = this.pais.CountryId;
}
function resetForm() {
  this.buque.VesselName = '';
  this.buque.Imo = 'IMO';
  this.buque.Mmsi = '';
  this.buque.CallSign = '';
  this.buque.Loa = '';
  this.buque.Lwl = '';
  this.buque.Teus = '';
  this.buque.Gross = '';
  this.buque.DraftBow = '';
  this.buque.DraftStern = '';
  this.buque.SummerDwt = '';
  this.buque.VesselCrane = null;
  this.buque.FuelOilCapacity = '';
  this.buque.Status = 1;
  this.FgActVessel = false;
  this.tpBuque = null;
  this.pais = null;
  this.titleModal= '';
  this.$v.$reset();
}
function handleFileUpload(image) {
  this.buque.VesselRoute = this.$refs.imageInput.file;
  this.image = '/img/uploadPhotoEs.svg';
}

function listBuques(){
  this.resetForm();
  this.$router.push({ name: this.$i18n.locale == 'es'? 'BUQUE' : 'VESSEL' });
}
function cancelForm(){
  this.$router.go(-1);
}
function fuseSearchPais(options, search) {
  const fuse = new Fuse(options, {
    keys: ["IsoCodealpha2", "IsoCodeAlpha3", "CountryName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}
function loadData() {
  this.isLoadingGeneral = true;

  let peticiones = this.isEditMode
    ?[
      this.$http.ejecutar('GET', 'TpVessel-list', { Filter: 'ACTIVO' }),
      this.$http.ejecutar('GET', 'Country-list', { Filter: 'ACTIVO' }),
      this.$http.ejecutar('GET', 'Vessel-by-id', { VesselId: this.VesselId })
    ]
    :[
      this.$http.ejecutar('GET', 'TpVessel-list', { Filter: 'ACTIVO' }),
      this.$http.ejecutar('GET', 'Country-list', { Filter: 'ACTIVO' })
    ];

  Promise.all(peticiones)
  .then((responses) => {
    if(this.isEditMode){
      this.tpBuques = responses[0].data.data;
      this.paises = responses[1].data.data;
      this.setData(responses[2].data.data[0]);
      this.savedBuque = {...responses[2].data.data[0]};
      this.isSaved = true;
    }else{
      this.tpBuques = responses[0].data.data;
      this.paises = responses[1].data.data;
    }

  }).catch((err) => {

    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingGeneral = false;
  });
}
function setData(buqueData){
  this.buque.TpVesselId = buqueData.TpVesselId;
  this.buque.CountryId = buqueData.CountryId;
  this.buque.VesselName = buqueData.VesselName;
  this.buque.Imo = buqueData.Imo;
  this.buque.Mmsi = buqueData.Mmsi?buqueData.Mmsi:'';
  this.buque.CallSign = buqueData.CallSign?buqueData.CallSign:'';
  this.buque.Loa = buqueData.Loa;
  this.buque.Lwl = buqueData.Lwl;
  this.buque.Teus = buqueData.Teus;
  this.buque.Gross = buqueData.Gross;
  this.buque.DraftBow = buqueData.DraftBow;
  this.buque.DraftStern = buqueData.DraftStern;
  this.buque.SummerDwt = buqueData.SummerDwt;
  this.buque.VesselCrane = buqueData.VesselCrane?1:0;
  this.buque.FuelOilCapacity = buqueData.FuelOilCapacity;
  this.buque.Status = buqueData.FgActVessel?1:0;
  this.FgActVessel = buqueData.FgActVessel;

  this.tpBuque = {
    TpVesselId: buqueData.TpVesselId,
    TpVesselName: buqueData.TpVesselName
  };
  this.pais = {
    CountryId: buqueData.CountryId,
    CountryName: buqueData.CountryName
  };

  let viewVesselType = [];
  if(buqueData.PlanViewRoute !== null && buqueData.PlanViewRoute !== ''){
    viewVesselType.push({
      thumb: `${this.$store.getters["connection/getBase"]}${buqueData.PlanViewRoute.replace('public/', '', null, 'i')}`, 
      src: `${this.$store.getters["connection/getBase"]}${buqueData.PlanViewRoute.replace('public/', '', null, 'i')}`,
    });
  }

  if(buqueData.TransversalViewRoute !== null && buqueData.TransversalViewRoute !== ''){
    viewVesselType.push({
      thumb: `${this.$store.getters["connection/getBase"]}${buqueData.TransversalViewRoute.replace('public/', '', null, 'i')}`, 
      src: `${this.$store.getters["connection/getBase"]}${buqueData.TransversalViewRoute.replace('public/', '', null, 'i')}`,
    });
  }

  this.media = viewVesselType;

  this.touchRequiredValues();
}

function getTpBuques() {
  this.isLoadingTpBuque = true;
  this.$http.ejecutar('GET', 'TpVessel-list', null, { Filter: 'ACTIVO' })
  .then(response => {
    this.tpBuques = response.data.data;
  }).catch( err => {
    
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingTpBuque = false;
  });
}
function toggleTpBuqueAdd() {
  this.modalAddTpBuque = true;
}
function handleTpBuqueSubmit() {
  this.getTpBuques();
}

function handleTab(tab) {
  this.activeTab = tab;
}
function isActiveTab(current) {
  return this.activeTab == current;
}
function statusConfirmation(){
  if(!this.edit)
    this.submit();
  else{
    let status = this.FgActVessel?1:0;
    if(this.buque.Status === status)
      this.submit();
    else{
      this.$swal.fire({
        text: `${this.$t('label.changeStatusQuestion')} ${this.buque.VesselName}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#42AA91',
        cancelButtonColor: '#E1373F',
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          this.submit();
        }
      });
    }
  }
}
function handleInfo() {
    this.$refs.lightbox.showImage(0);
}
function isTeusValid(field) {
  return field.$dirty 
    ? this.$v.buque.Teus.$model == ''? false : !field.$error
    : null
}
function toggleAddDoc() {
  this.docVesselId = (this.savedBuque.VesselId !== '') ? this.savedBuque.VesselId : this.VesselId; //this.savedBuque.VesselId?this.savedBuque.VesselId:'';
  this.addDocModal = true;
  this.titleModal = this.$t('label.vesselDocuments')+': '+this.buque.VesselName;
}
function handleDocsSubmit() {
  // this.docVesselId = '';
}

function OpenModal(type,title){
  this.ShowModal = true;
  this.Type= type;
  this.titleHelpModal = title;
}

function EventImg(){
  let Dropdown = document.getElementById("dropdown-help");
  Dropdown.classList.toggle("show");
  Dropdown.lastChild.classList.toggle("show");
  Dropdown.lastChild.classList.toggle("dropdown-center-help")
}

function Close(){
  this.ShowModal = false;
  this.Type = 0;
  this.titleHelpModal = ''
}

//Computeds:
function isPaisesEmpty(){
  return this.paises.length == 0;
}
function paisOptions() {
  return this.paises.map((pais) => Object.assign({}, pais, {
    label: pais.CountryName,
    value: pais.CountryId
  }));
}
function isPaisValid() {
  return {
    'invalid-feedback': true,
    invalid: this.pais? null : this.$v.$anyDirty? this.$v.pais.$invalid : null
  };
}
function isTpBuquesEmpty(){
  return this.tpBuques.length == 0;
}
function tpBuquesOptions() {
  return this.tpBuques.map((tpBuque) => Object.assign({}, tpBuque, {
    label: tpBuque.TpVesselName,
    value: tpBuque.TpVesselId
  }));
}
function isTpBuqueValid() {
  return {
    'invalid-feedback': true,
    invalid: this.tpBuque? null : this.$v.$anyDirty? this.$v.tpBuque.$invalid : null
  };
}
function fgGruaOptions() {
  return [
    {
      value: 1,
      label: this.$t('label.yes')
    },
    {
      value: 0,
      label: 'NO'
    }
  ]
}
function hasGrua() {
  if(this.isEditMode)
    return this.savedBuque.VesselCrane && this.savedBuque.VesselId;
  else
    return (this.buque.VesselCrane == 1 && this.isSaved && this.savedBuque.VesselId);
}
function isBuqueForm(){
  return this.activeTab == 0;
}
function isEditMode(){
  return this.VesselId != '';
}
function statusOptions() {
  return [
    {
      value: 1,
      label: this.$t('label.ACTIVO')
    },
    {
      value: 0,
      label: this.$t('label.INACTIVO')
    }
  ]
}
function statusSelectColor(){
  return this.buque.Status === 1;
}
function formTittle(){
  return !this.isEditMode
    ? this.$t('label.nuevo')+this.$t('label.vessel')
    : this.$t('label.edit')+this.$t('label.vessel')+`: ${this.buque.VesselName}`;
}
function isContainerShip() {
  return this.tpBuque?.TpVesselId.toUpperCase() == process.env.VUE_APP_CONTAINER_SHIP_VESSEL_ID;
}
function isGeneralCargo() {
  return this.tpBuque?.TpVesselId.toUpperCase() == process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID;
}
function canAddDoc() {
  return this.savedBuque.VesselId?true:false;
}
function imgInputTexts(){
  return imgInputTextsHelpers(this);
}

export default {
  name: 'form-buque',
  mixins: [BuqueMixin],
  props: {
    VesselId: {
      type: String,
      default: ''
    },
    VesselImage: {
      type: String,
      default: "",
    },
  },
  components: {
    PictureInput,
    AddTpBuqueModal,
    BuqueBodega,
    BuqueGrua,
    GruaBodega,
    LightBox,
    ModalHelpViews,
    CustomTabs,
    CustomTab,
    AddDocBuqueModal,
    BuqueBay,
    TransversalTab,
    GruaBay,
  },
  data,
  created,
  validations() {
    return BuqueValidations(this.isContainerShip||this.isGeneralCargo);
  },
  directives: UpperCase,
  methods: {
    submit,
    addBuque,
    editBuque,
    resetForm,
    handleFileUpload,
    listBuques,
    cancelForm,
    fuseSearchPais,
    getTpBuques,
    handleTpBuqueSubmit,
    toggleTpBuqueAdd,
    loadData,
    formatData,
    handleTab,
    isActiveTab,
    setData,
    statusConfirmation,
    handleInfo,
    submitFileAdd,
    submitFileEdit,
    isTeusValid,
    toggleAddDoc,
    handleDocsSubmit,
    OpenModal,
    EventImg,
    Close
  },
  computed: {
    isPaisesEmpty,
    paisOptions,
    isPaisValid,
    isTpBuquesEmpty,
    tpBuquesOptions,
    isTpBuqueValid,
    fgGruaOptions,
    hasGrua,
    isBuqueForm,
    isEditMode,
    statusOptions,
    statusSelectColor,
    formTittle,
    isContainerShip,
    isGeneralCargo,
    canAddDoc
  },
  watch: {
    VesselId: async function(newVesselId) {
      if(newVesselId){
        this.loadData();
      }
    },
    tpBuque: function() {
      if(!this.isContainerShip){
        this.buque.Teus = '';
        this.$v.buque.Teus.$reset();
      }
    }
  }
}
</script>
<style scoped>
.preview {
  font-size: 0em;
}
label.text-adjust {
  font-size: 0.8em;
}
.select-adjust {
  flex: 1 1 auto;
  margin-bottom: 0;
}
.vs--searchable .vs__dropdown-toggle {
  height: 100%;
}
</style>
<style>
.dropdown-center-help{
  position: absolute; 
  inset: 0px 0px auto auto; 
  margin: 0px; 
  transform: translate(0.4375px, 45px);
}
.position-dropdown{
  position: absolute;
  z-index: 1;
  width: 98%;
  top: 17%;
}
.position-icon{
 
  position: absolute;
  z-index: 1;
}
 #dropdown-help .dropdown-menu.show {
  display: block;
  top: -8px;
}
#dropdown-help .btn {
  height: 30px;
  width: 30px;
}
</style>

 