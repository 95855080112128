<template>
<div>
  <loading-overlay :active="isLoadingGeneral" :is-full-page="true" loader="bars" />
  <CModalExtended
    :title="`BAY PLAN: ${this.bay ? this.bay.BayCode : '' }`"
    color="dark"
    size="lg"
    :close-on-backdrop="false"
    :show.sync="modalActive"
  >
    <CRow>
      <CCol sm="12" class="min-container">
        <CCard>
          <CCardBody>
            <CRow align-horizontal="center">
              <CCol sm="8" v-if="toDeckHasData">
                <bay-grid
                  :col="colNum(false)"
                  :row="rowNum(false)"
                  :crossLine="isCrossLineTo"
                  :items="positionsToDeck"
                  showStatus
                  :showDashedNumbers="false"
                  :maxCol="responsiveCol(isCrossLineUnder)"
                />
              </CCol>
            </CRow>
            <hr class="dash">
            <CRow align-horizontal="center">
              <CCol sm="8" v-if="underDeckHasData">
                <bay-grid
                :col="colNum(true)"
                :row="rowNum(true)"
                :crossLine="isCrossLineUnder"
                :items="positionsUnderDeck"
                showStatus
                showNumbersBottom
                :showDashedNumbers="false"
                :maxCol="responsiveCol(isCrossLineTo)"
              />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>         
    <template #footer>
      <CButton
        shape="square"
        color="excel"
        size="sm"
        class="ml-2"
        @click.prevent="printPdf"
      >
        <div class="d-flex align-items-center">
          <CIcon name="cil-cloud-download"/>
          <span class="ml-1">{{$t('label.download')}}</span>
        </div>
      </CButton>
      <CButton
        shape="square"
        color="wipe"
        size="sm"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x"/>
        <span class="ml-1">{{ $t('button.cancel') }}</span>
      </CButton>
    </template>
  </CModalExtended>
</div>
</template>

<script>
import { mapState } from "vuex";
import BayGrid from "./bay-grid";
import { BayMixin } from '@/_mixins/buque';

//Data
function data() {
  return {
    loaded: false,
    modalActive: this.modal,
    items: [],
    isLoadingGeneral: false,
    bayInfo: {},
    toDeckPos: [],
    underDeckPos: [],
  }
}

//Methods
function toggle(newVal) {
  if(!newVal)
    this.$store.commit('baygrid/defaultValues');
  else{
    this.getBayInfo();
  }
    
  this.modalActive = newVal;
}
function getBayInfo() {
  this.isLoadingGeneral = true;
  this.$store.dispatch('baygrid/getBayById', this.bay.VesselBayId)
    .then((response) => {
      this.bayInfo = {...this.bay,...response};
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isLoadingGeneral = false;
    });
}

function printPdf() {
  if(!this.currentBay || (Object.keys(this.buque).length == 0 && !this.buque.VesselId))
    return;

  let pageInfo = {
    currentBay: this.currentBay,
    vesselId: this.buque.VesselId,
    vesselRoute: this.buque.VesselRoute,
    bayCode: this.currentBay.BayCode,
    positionsToDeck: this.positionsToDeck,
    positionsUnderDeck: this.positionsUnderDeck,
    type: 'bay-plan',
  };

  localStorage.setItem('pageInfo', JSON.stringify(pageInfo));

  let routeData = this.$router.resolve({name: 'pdfBayPlan'});
  window.open(routeData.href, '_blank');
}

//Computed

export default {
  name: 'bay-plan-modal',
  props: {
    modal: Boolean,
    bay: {
      type: Object,
      default: null,
    },
    buque: {
      type: Object,
      required: true,
      default: () => {}
    },
  },
  mixins: [BayMixin],
  components: {
    BayGrid
  },
  data,
  methods: {
    toggle,
    getBayInfo,
    printPdf,
  },
  computed: {
    ...mapState({
      currentBay: state => state.baygrid.bay,
      positionsToDeck: state => state.baygrid.positionsToDeck,
      positionsUnderDeck: state => state.baygrid.positionsUnderDeck
    }),
  },
  watch: {
    modal: function(val) {
      this.toggle(val);
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    },
    positionsToDeck: function(newPos) {
      this.toDeckPos = [...newPos];
    },
    positionsUnderDeck: function(newPos) {
      this.underDeckPos = [...newPos];
    }
  }
}
</script>

<style scoped>
.min-container {
  min-height: 390px;
}
.dash{
  border: 0 none;
  border-top: 1px dashed #322f32;
  background: none;
  height:0;
  width: 80%;
  margin: 10px auto;
}
</style>