import {
  required,
  maxLength
} from 'vuelidate/lib/validators';

import { alphaNumSpecials, metrics } from '../funciones';

const metricsNotReq = (value) => !helpers.req(value) || metrics(value);

const textNumSpecial = (value) => /^[a-zA-Z0-9 \u00f1\u00d1\-]+$/g.test(value);

export default () => {
  return {
    grua: {
      CraneName: { required, textNumSpecial, maxLength: maxLength(150) },
      FgOperational: { required },
      MaxWeigth: { required },
      MaxHeigth: { required }
    },
    TpGrua: { required }
  }
}