import {
    required,
    maxLength,
    helpers
  } from 'vuelidate/lib/validators';
  
  import { alphaSpecials } from '../funciones';
  
  const isValidFile = (value) => {
    if(!value)
      return false;
    
    if(value.type)
      return value.type.startsWith('image');
    else
        return (value != '');
  };

  const fileNotReq = (value) => !helpers.req(value) || isValidFile(value);

  const textarea = (value) => /^[\w \u00f1\u00d1,.*\+\-\#\/\$\!\(\)\?\¡\¿]+$/gm.test(value);

  export default () => {
    return {
      tipoBuque: {
        TpVesselName: { required, alphaSpecials, maxLength: maxLength(150) },
        TpVesselDs: { required, textarea, maxLength: maxLength(500) },
        Route: { isValidFile }
      }
    }
  }